import axios from 'axios';
import utils from './utils';
import { useState, useEffect } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { Container, TableContainer, Typography, Table, TableHead, TableRow, TableCell, TableBody, Paper, Stack, CircularProgress } from '@mui/material';


const AdminLeads = () => {

    const [leads, setLeads] = useState([] as any[]);
    const [bars, setBars] = useState([] as number[]);
    const [avgTime, setAvgTime] = useState(0);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const [statusMessage, setStatusMessage] = useState('');

    useEffect(() => {
        loadData();
    }, []);

    const dataTitle: string[] = [];
    for (let i = 0; i < 6; i++) {
        dataTitle.push(i.toString());
    }
    dataTitle.push('6-9');
    dataTitle.push('10-14');
    dataTitle.push('15-19');
    dataTitle.push('20-29');
    dataTitle.push('30-1m');
    dataTitle.push('1m-2m');
    dataTitle.push('2m+');

    const printEvents = (payload: any) => {
        const keys = Object.keys(payload).sort();
        const events = keys.map(k => `${Math.round(Number(k) / 1000)}: ${payload[k].event}`);
        return events.join(" -> ");
    }

    const loadData = async () => {
        try {
            const res = await axios.get('/api/me', { headers: utils.getAuthHeaders(), validateStatus: () => true });
            if (res.status == 200) {
                const { success, me } = res.data;
                if (success && me.email == 'michelerosellib@gmail.com') {
                    try {
                        const res = await axios.get(`/api/adminleads`, { headers: utils.getAuthHeaders() });
                        const { success, error } = res.data;
                        if (success) {
                            setLeads(res.data.specialLeads);
                            setAvgTime(res.data.avgTime);
                            setTotal(res.data.total);
                            setBars(res.data.bars);
                        } else {
                            setStatusMessage(error);
                        }
                    } catch (error) {
                        setStatusMessage("Si è verificato un errore imprevisto sul nostro server.");
                        console.log(error)
                    }
                    setLoading(false);
                    return;
                }
            }
        } catch (error) {
            console.log(error)
        }
        setLoading(false);
        if (process.env.REACT_APP_ENV != 'test') {
            window.location.href = '/login';
        }
    }

    return (
        <div>
            {loading ? (<Stack sx={{ mt: 5 }} alignItems='center'><CircularProgress disableShrink /></Stack>) : (
                <Container>
                    <Paper sx={{ px: 3, pt: 3, pb: 2, mt: 5, backgroundColor: '#282520' }}>
                        <Typography variant="h4" color="#ECE9E6" gutterBottom sx={{ pl: 1, mb: 2 }}>
                            Leads
                        </Typography>
                        <Typography variant="h5" color="#ECE9E6" gutterBottom sx={{ pl: 1, mb: 2 }}>
                            Tot: <span style={{ color: '#81c784' }}>{total}</span> | Tempo medio: <span style={{ color: '#81c784' }}>{avgTime.toFixed(2)}</span>s
                        </Typography>
                        <BarChart
                            xAxis={[
                                {
                                    id: 'barCategories',
                                    data: dataTitle,
                                    scaleType: 'band',
                                },
                            ]}
                            series={[
                                {
                                    data: bars,
                                },
                            ]}
                            // width={500}
                            height={400}
                        />
                        <TableContainer sx={{ width: '100%', overflowX: 'auto' }}>
                            <Table size='small' sx={{ mt: 3 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ color: "rgba(255, 255, 255, 0.4)" }}>DATA</TableCell>
                                        <TableCell sx={{ color: "rgba(255, 255, 255, 0.4)" }}>EVENTI</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {leads.map(lead => (<TableRow>
                                        <TableCell>{utils.formatData(lead.timestamp)}</TableCell>
                                        <TableCell>{printEvents(lead.events)}</TableCell>
                                    </TableRow>)
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {statusMessage && (
                            <Paper variant='outlined' sx={{
                                p: 2, my: 1, flexGrow: 1,
                                backgroundColor: "#e57373",
                                borderColor: '#d32f2f',
                                color: 'black'
                            }} role="alert">
                                {statusMessage}
                            </Paper>
                        )}
                    </Paper>
                </Container >)}
        </div >
    );
};

export default AdminLeads;
