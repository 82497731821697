import axios from 'axios';
import utils from './utils';
import Youtube from './Youtube';
import { useEffect, useState } from 'react';
import { Container, Typography, TextField, Paper, Stack, Divider, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Button, ButtonBase } from '@mui/material';
import { Send } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

import Navbar from './Navbar';


const Assistance = () => {

    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isOperator, setIsOperator] = useState(false);
    const [email, setEmail] = useState("");
    const [isLoadingMessage, setLoadingMessage] = useState(false);
    const [statusMessage, setStatusMessage] = useState("");
    const [isError, setIsError] = useState(false);
    const [subject, setSubject] = useState("");
    const [content, setContent] = useState("");

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            const res = await axios.get('/api/me', { headers: utils.getAuthHeaders(), validateStatus: () => true });
            if (res.status == 200) {
                const { success, error, me } = res.data;
                if (success) {
                    setIsLoading(false);
                    setIsOperator(me.op)
                    setEmail(me.email);
                    return; // stay on the page
                } else if (error) {
                    console.log(error);
                }
            }
        } catch (error) {
            console.log(error)
        }
        setIsLoading(false);
        if (process.env.REACT_APP_ENV != 'test') {
            window.location.href = '/login';
        }
    }

    const handleSubmitMessage = async (e: any) => {
        e.preventDefault();
        try {
            setStatusMessage("");
            setLoadingMessage(true);
            const res = await axios.post('/api/assistenza', { subject, content }, { headers: utils.getAuthHeaders() });
            const { success, error } = res.data;
            if (success) {
                setIsError(false);
                setStatusMessage("Messaggio inviato con successo.");
                setContent("");
                setSubject("");
            } else {
                setIsError(true);
                setStatusMessage(error);
            }
        } catch (error) {
            setIsError(true);
            setStatusMessage("Si è verificato un errore imprevisto sul nostro server.");
            console.log(error)
        }
        setLoadingMessage(false);
    };

    return (
        <div>
            <Navbar logged={true} isOp={isOperator} />
            <Container>
                <Typography variant="h3" color="#ECE9E6" gutterBottom>
                    Scrivici, per qualsiasi cosa.
                </Typography>
                <ButtonBase onClick={() => setOpen(true)}>
                    <Typography variant="h6" color="#ECE9E6" gutterBottom sx={{ pb: 2, pl: 1 }}>
                        Per chiarimenti sull'utilizzo di MyExperts <span style={{ color: "#E5C699" }}>guarda il tutorial</span>!
                    </Typography>
                </ButtonBase>
                <Paper sx={{ px: 3, pt: 3, pb: 2, backgroundColor: '#282520' }}>
                    {isLoading ? (<Stack sx={{ mt: 5 }} alignItems='center'><CircularProgress disableShrink /></Stack>) : (
                        <div>
                            <Typography gutterBottom sx={{ pl: 1, mb: 2 }}>
                                {`Risponderemo tua email: ${email}`}
                            </Typography>
                            <form onSubmit={handleSubmitMessage}>
                                <Stack direction='row'>
                                    <TextField
                                        size="small"
                                        required
                                        label="Titolo del Messaggio"
                                        value={subject}
                                        onChange={e => setSubject(e.target.value)}
                                        onInvalid={F => (F.target as HTMLInputElement).setCustomValidity('Questo campo è obbligatorio')}
                                        onInput={F => (F.target as HTMLInputElement).setCustomValidity('')}
                                        sx={{ flexGrow: 1, mr: 1, pr: 1 }}
                                    />
                                    <LoadingButton
                                        endIcon={<Send />}
                                        loading={isLoadingMessage}
                                        loadingPosition="end"
                                        variant="contained"
                                        type="submit"
                                        sx={{ backgroundColor: '#53432D', color: '#ECE9E6' }}
                                    >
                                        <span>Invia</span>
                                    </LoadingButton>
                                </Stack>
                                <div style={{ display: 'flex' }}>
                                    <TextField
                                        size="small"
                                        required
                                        label="Contenuto"
                                        value={content}
                                        onChange={e => setContent(e.target.value)}
                                        onInvalid={F => (F.target as HTMLInputElement).setCustomValidity('Questo campo è obbligatorio')}
                                        onInput={F => (F.target as HTMLInputElement).setCustomValidity('')}
                                        multiline
                                        rows={10}
                                        sx={{ flexGrow: 1, mt: 2, mb: 2 }}
                                    />
                                </div>
                            </form>
                            <Divider sx={{ mt: 2 }}>OPPURE</Divider>
                            <Typography variant='h6' gutterBottom sx={{ pl: 1, my: 2 }}>
                                Mandaci una email a: <a href="mailto:info@myexperts.it" target="_top" style={{ color: "#E5C699" }}>info@myexperts.it</a>
                            </Typography>
                            {isOperator ? (<div><Divider>OPPURE</Divider>
                                <Typography variant='h6' gutterBottom sx={{ pl: 1, my: 2 }}>
                                    Chiamaci al numero: 3515015849
                                </Typography></div>) : (<div />)}
                        </div>)
                    }
                    {statusMessage && (
                        <Paper variant='outlined' sx={{
                            p: 2, mt: 2, flexGrow: 1,
                            backgroundColor: isError ? "#e57373" : "#81c784 ",
                            borderColor: isError ? '#d32f2f' : '#388e3c',
                            color: 'black'
                        }} role="alert">
                            {statusMessage}
                        </Paper>
                    )}
                </Paper>
            </Container >
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth={true}
                maxWidth='lg'
                aria-labelledby="dialog-title-1"
                sx={{ p: 2 }}
                PaperProps={{
                    style: {
                        backgroundColor: '#282520',
                    },
                }}
            >
                <DialogTitle align='center' fontSize='1.6em' id="dialog-title-1">
                    Tutorial {isOperator ? "Account Professionista" : "Account Cliente"}
                </DialogTitle>
                <DialogContent sx={{ pb: 0 }}>
                    <Youtube isOp={isOperator} title="Tutorial" />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Chiudi</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default Assistance;
