import axios from 'axios';
import utils from './utils';
import Youtube from './Youtube';
import { useState, useEffect } from 'react';
import { Container, TableContainer, Typography, TextField, ButtonBase, Table, TableHead, TableRow, TableCell, TableBody, InputAdornment, Paper, SxProps, IconButton, Stack, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, CircularProgress } from '@mui/material';
import { AddCircleOutline, ArrowBackIos, ArrowForwardIos, DeleteOutline, Pending, Search } from '@mui/icons-material';

import Navbar from './Navbar';

const dataRowSX: SxProps = {
    display: "table-row",
    ":hover": {
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        cursor: "pointer",
    },
};

const mouseDown = (e: any) => {
    e.stopPropagation();
}

const UserHome = () => {
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [opToDelete, setOpToDelete] = useState({ id: 0, nome: "", cognome: "" });

    const [ops, setOps] = useState([] as any[]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [statusMessage, setStatusMessage] = useState('');

    const [search, setSearch] = useState('');
    const [opsSearch, setOpsSearch] = useState([] as any[]);
    const [totalSearch, setTotalSearch] = useState(0);
    const [pageSearch, setPageSearch] = useState(0);
    const [isLoadingSearch, setLoadingSearch] = useState(false);
    const [statusMessageSearch, setStatusMessageSearch] = useState('');

    useEffect(() => {
        loadOps(0);
    }, []);

    const loadOps = async (p: number) => {
        try {
            const res = await axios.get(`/api/myops?page=${p}`, { headers: utils.getAuthHeaders() });
            const { success, error } = res.data;
            if (success) {
                setOps(res.data.users);
                setTotal(res.data.total);
            } else {
                setStatusMessage(error);
            }
        } catch (error) {
            setStatusMessage("Si è verificato un errore imprevisto sul nostro server.");
            console.log(error)
        }
        setLoading(false);
    }

    const loadOpsSearch = async (p: number, q: string) => {
        try {
            const res = await axios.get(`/api/ops?page=${p}${q ? '&q=' + q : ""}`, { headers: utils.getAuthHeaders() });
            const { success, error } = res.data;
            if (success) {
                setOpsSearch(res.data.users);
                setTotalSearch(res.data.total);
            } else {
                setStatusMessageSearch(error);
            }
        } catch (error) {
            setStatusMessageSearch("Si è verificato un errore imprevisto sul nostro server.");
            console.log(error)
        }
        setLoadingSearch(false);
    }

    const handlePage = (forward: boolean) => {
        if (page == 0 && !forward) {
            return;
        }
        if (5 * (page + 1) >= total && forward) {
            return;
        }
        const newPage = page + (forward ? 1 : -1)
        setPage(newPage);
        setLoading(true);
        loadOps(newPage);
    }

    const handlePageSearch = (forward: boolean) => {
        if (pageSearch == 0 && !forward) {
            return;
        }
        if (5 * (pageSearch + 1) >= totalSearch && forward) {
            return;
        }
        const newPage = pageSearch + (forward ? 1 : -1)
        setPageSearch(newPage);
        setLoadingSearch(true);
        loadOpsSearch(newPage, search);
    }

    const handleSearch = (e: any) => {
        setPageSearch(0);
        setSearch(e.target.value);
        setLoadingSearch(true);
        loadOpsSearch(0, e.target.value);
    }

    const handleClose = () => {
        setOpToDelete({ id: 0, nome: "", cognome: "" });
        setOpen(false);
    };

    const handleClose2 = () => {
        setOpen2(false);
    }

    const handleConfirmDelete = () => {
        deleteOp(opToDelete.id);
        setOpToDelete({ id: 0, nome: "", cognome: "" });
        setOpen(false);
    };

    const openDeleteDialog = (e: any, op: any, confermato: boolean) => {
        e.stopPropagation();
        if (confermato) {
            setOpToDelete(op);
            setOpen(true);
        } else {
            deleteOp(op.id);
        }
    }

    const openInfoDialog = (e: any) => {
        e.stopPropagation();
        setOpen2(true);
    }

    const addOp = async (e: any, id: number) => {
        e.stopPropagation();
        try {
            if (isLoading) {
                return;
            }
            setStatusMessageSearch("");
            setLoading(true);
            const res = await axios.post('/api/askop', { id }, { headers: utils.getAuthHeaders() });
            const { success, error } = res.data;
            if (success) {
                setPage(0);
                loadOps(0);
            } else {
                setLoading(false);
                setStatusMessageSearch(error);
            }
        } catch (error) {
            setLoading(false);
            setStatusMessageSearch("Si è verificato un errore imprevisto sul nostro server.");
            console.log(error)
        }
    }

    const deleteOp = async (id: number) => {
        try {
            if (isLoading) {
                return;
            }
            setStatusMessage("");
            setLoading(true);
            const res = await axios.post('/api/deleteop', { id }, { headers: utils.getAuthHeaders() });
            const { success, error } = res.data;
            if (success) {
                setPage(0);
                loadOps(0);
            } else {
                setLoading(false);
                setStatusMessage(error);
            }
        } catch (error) {
            setLoading(false);
            setStatusMessage("Si è verificato un errore imprevisto sul nostro server.");
            console.log(error)
        }
    };

    const openOp = (id: number) => {
        return function () {
            window.location.href = `/user/op?id=${id}`;
        }
    }

    return (
        <div>
            <Navbar logged={true} isOp={false} />
            <Container>
                <Paper sx={{ px: 3, pt: 3, pb: 2, mt: 5, backgroundColor: '#282520' }}>
                    <Typography variant="h4" color="#ECE9E6" gutterBottom sx={{ pl: 1, mb: 2 }}>
                        I tuoi Professionisti
                    </Typography>
                    {isLoading ? (<Stack sx={{ my: 3 }} alignItems='center'><CircularProgress disableShrink /></Stack>) :
                        (<div>
                            <TableContainer sx={{ width: '100%', overflowX: 'auto' }}>
                                <Table size='small' sx={{ mt: 3 }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ color: "rgba(255, 255, 255, 0.4)" }}>COGNOME</TableCell>
                                            <TableCell sx={{ color: "rgba(255, 255, 255, 0.4)" }}>NOME</TableCell>
                                            <TableCell sx={{ color: "rgba(255, 255, 255, 0.4)" }}>EMAIL</TableCell>
                                            <TableCell sx={{ color: "rgba(255, 255, 255, 0.4)" }}>TELEFONO</TableCell>
                                            <TableCell align='center' sx={{ color: "rgba(255, 255, 255, 0.4)" }}>IN_ATTESA</TableCell>
                                            <TableCell align='center' sx={{ color: "rgba(255, 255, 255, 0.4)" }}>ELIMINA</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {ops.map(op => {
                                            if (op.confermato) {
                                                return (<ButtonBase onClick={openOp(op.id)} key={op.id} component={TableRow} sx={dataRowSX}>
                                                    <TableCell>{op.cognome}</TableCell>
                                                    <TableCell>{op.nome}</TableCell>
                                                    <TableCell>{op.email}</TableCell>
                                                    <TableCell>{op.telefono}</TableCell>
                                                    <TableCell>
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        <IconButton sx={{ p: 0 }} onMouseDown={mouseDown} onClick={(e) => openDeleteDialog(e, op, true)}>
                                                            <DeleteOutline color='error' />
                                                        </IconButton>
                                                    </TableCell>
                                                </ButtonBase>)
                                            } else {
                                                return (<TableRow>
                                                    <TableCell>{op.cognome}</TableCell>
                                                    <TableCell>{op.nome}</TableCell>
                                                    <TableCell>{op.email}</TableCell>
                                                    <TableCell>{op.telefono}</TableCell>
                                                    <TableCell align='center'>
                                                        <IconButton sx={{ p: 0 }} onMouseDown={mouseDown} onClick={(e) => { openInfoDialog(e) }}>
                                                            <Pending color='warning' />
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        <IconButton sx={{ p: 0 }} onMouseDown={mouseDown} onClick={(e) => openDeleteDialog(e, op, false)}>
                                                            <DeleteOutline color='error' />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>)
                                            }
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Stack direction='row' alignItems='center' sx={{ pt: 1 }}>
                                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
                                <Typography fontSize='1.1rem' sx={{ pr: 2, color: "rgba(255, 255, 255, 0.8)" }}>
                                    {`${page * 5 + 1} - ${page * 5 + ops.length} di ${total}`}
                                </Typography>
                                <IconButton onClick={() => handlePage(false)} disabled={page == 0}><ArrowBackIos /></IconButton>
                                <IconButton onClick={() => handlePage(true)} disabled={5 * (page + 1) >= total}><ArrowForwardIos /></IconButton>
                            </Stack>
                        </div>)
                    }
                    {statusMessage && (
                        <Paper variant='outlined' sx={{
                            p: 2, my: 1, flexGrow: 1,
                            backgroundColor: "#e57373",
                            borderColor: '#d32f2f',
                            color: 'black'
                        }} role="alert">
                            {statusMessage}
                        </Paper>
                    )}
                </Paper>
                <Paper sx={{ px: 3, pt: 3, pb: 2, mt: 3, mb: 2, backgroundColor: '#282520' }}>
                    <Typography variant="h4" color="#ECE9E6" gutterBottom sx={{ pl: 1, mb: 2 }}>
                        Ricerca Professionisti
                    </Typography>
                    <div style={{ display: 'flex' }}>
                        <TextField
                            id="outlined-size-small"
                            size="small"
                            placeholder='Cerca...'
                            onChange={handleSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ flexGrow: 1 }}
                        />
                    </div>
                    {isLoadingSearch ? (<Stack sx={{ my: 3 }} alignItems='center'><CircularProgress disableShrink /></Stack>) :
                        (<div>
                            <TableContainer sx={{ width: '100%', overflowX: 'auto' }}>
                                <Table size='small' sx={{ mt: 3 }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ color: "rgba(255, 255, 255, 0.4)" }}>COGNOME</TableCell>
                                            <TableCell sx={{ color: "rgba(255, 255, 255, 0.4)" }}>NOME</TableCell>
                                            <TableCell sx={{ color: "rgba(255, 255, 255, 0.4)" }}>DENOM.</TableCell>
                                            <TableCell sx={{ color: "rgba(255, 255, 255, 0.4)" }}>PROVINCIA</TableCell>
                                            <TableCell align='center' sx={{ color: "rgba(255, 255, 255, 0.4)" }}>AGGIUNGI</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {opsSearch.map(op => (<TableRow>
                                            <TableCell>{op.cognome}</TableCell>
                                            <TableCell>{op.nome}</TableCell>
                                            <TableCell>{op.nome_azienda}</TableCell>
                                            <TableCell>{op.provincia}</TableCell>
                                            <TableCell align='center'>
                                                <IconButton sx={{ p: 0 }} onMouseDown={mouseDown} onClick={(e) => addOp(e, op.id)}>
                                                    <AddCircleOutline color='success' />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>)
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Stack direction='row' alignItems='center' sx={{ pt: 1 }}>
                                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
                                <Typography fontSize='1.1rem' sx={{ pr: 2, color: "rgba(255, 255, 255, 0.8)" }}>
                                    {`${pageSearch * 5 + 1} - ${pageSearch * 5 + opsSearch.length} di ${totalSearch}`}
                                </Typography>
                                <IconButton onClick={() => handlePageSearch(false)} disabled={pageSearch == 0}><ArrowBackIos /></IconButton>
                                <IconButton onClick={() => handlePageSearch(true)} disabled={5 * (pageSearch + 1) >= totalSearch}><ArrowForwardIos /></IconButton>
                            </Stack>
                        </div>)
                    }
                    {statusMessageSearch && (
                        <Paper variant='outlined' sx={{
                            p: 2, my: 1, flexGrow: 1,
                            backgroundColor: "#e57373",
                            borderColor: '#d32f2f',
                            color: 'black'
                        }} role="alert">
                            {statusMessageSearch}
                        </Paper>
                    )}
                </Paper>
                <Container sx={{ textAlign: "center" }}>
                    <ButtonBase onClick={() => setOpen3(true)}>
                        <Typography variant="h6" color="#ECE9E6" gutterBottom sx={{ pb: 2, pl: 1 }}>
                            Hai qualche dubbio su come utilizzare MyExperts? <span style={{ color: "#E5C699" }}>Guarda il tutorial</span>!
                        </Typography>
                    </ButtonBase>
                </Container>
            </Container >
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                sx={{ p: 2 }}
                PaperProps={{
                    style: {
                        backgroundColor: '#282520',
                    },
                }}
            >
                <DialogTitle id="dialog-title">
                    {`Rimuovere il Professionista ${opToDelete.cognome} ${opToDelete.nome}?`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-description">
                        Per rientrare tra i suoi clienti, dovrai richiedere di nuovo il collegamento.
                        Questa operazione non eliminerà la vostra chat e i documenti che avete scambiato.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Annulla</Button>
                    <Button color='error' onClick={handleConfirmDelete}>Elimina</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open2}
                onClose={handleClose2}
                aria-labelledby="info-dialog-title"
                aria-describedby="info-dialog-description"
                sx={{ p: 2 }}
                PaperProps={{
                    style: {
                        backgroundColor: '#282520',
                    },
                }}
            >
                <DialogTitle id="info-dialog-title">
                    {`In attesa di conferma`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="info-dialog-description">
                        Questo Professionista dovrà accettare il collegamento prima che tu possa contattarlo.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose2}>Va bene</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open3}
                onClose={() => setOpen3(false)}
                fullWidth={true}
                maxWidth='lg'
                aria-labelledby="dialog-title-1"
                sx={{ p: 2 }}
                PaperProps={{
                    style: {
                        backgroundColor: '#282520',
                    },
                }}
            >
                <DialogTitle align='center' fontSize='1.6em' id="dialog-title-1">
                    Tutorial Account Cliente
                </DialogTitle>
                <DialogContent sx={{ pb: 0 }}>
                    <Youtube isOp={false} title="Tutorial" />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen3(false)}>Chiudi</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default UserHome;
