import { Container, Typography, Link, Box } from '@mui/material';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#282520',
        width: '100%',
        mt: 'auto',
      }}
    >
      <Container maxWidth="lg">
        <Box>
          <Typography variant="body2" color="rgba(255,255,255,0.5)" align="center" sx={{ py: 3 }}>
            {"Copyright © "}
            <Link color="inherit" href="https://www.myexperts.it/">
              MyExperts
            </Link>{" "}
            {new Date().getFullYear()}
            {" | "}
            <Link color="inherit" href="/termini">
              Termini di Servizio
            </Link>{" | "}
            <Link color="inherit" href="/privacy">
              Privacy Policy
            </Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
