import axios from 'axios';
import utils from './utils';
import Youtube from './Youtube';
import { useState, useEffect } from 'react';
import { Container, TableContainer, Typography, TextField, ButtonBase, Table, TableHead, TableRow, TableCell, TableBody, InputAdornment, Paper, SxProps, IconButton, Stack, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, CircularProgress, Grid, Box } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos, CheckCircleOutline, DeleteOutline, Search } from '@mui/icons-material';

import Navbar from './Navbar';

type User = {
    id: number;
    nome: string;
    cognome: string;
    email: string;
    telefono: string;
    confermato: boolean;
}

const dataRowSX: SxProps = {
    display: "table-row",
    ":hover": {
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        cursor: "pointer",
    },
};

const indexCalendar = [0, 1, 2, 3, 4, 5, 6];

const mouseDown = (e: any) => {
    e.stopPropagation();
}

const OpHome = () => {
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [userToDelete, setUserToDelete] = useState({ id: 0, nome: "", cognome: "" });
    const [selectedSpot, setSelectedSpot] = useState({ i: 0, j: 0, o: 0 });

    const [scadenza, setScadenza] = useState(0);
    const [calendar, setCalendar] = useState([] as any[]);
    const [indexOre, setIndexOre] = useState([] as number[]);
    const [calendarPage, setCalendarPage] = useState(0);
    const [calendarLoading, setCalendarLoading] = useState(true);
    const [statusMessageCalendar, setStatusMessageCalendar] = useState('');

    const [users, setUsers] = useState([] as User[]);
    const [userSearch, setUserSearch] = useState("");
    const [userTotal, setUserTotal] = useState(0);
    const [userPage, setUserPage] = useState(0);
    const [userLoading, setUserLoading] = useState(true);
    const [statusMessageUsers, setStatusMessageUsers] = useState('');

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        loadData();
        loadUsers(0, "");
        loadCalendar(0);
    }, []);

    const now = Date.now();

    const loadData = async () => {
        try {
            const res = await axios.get('/api/me', { headers: utils.getAuthHeaders(), validateStatus: () => true });
            if (res.status == 200) {
                const { success, error, me } = res.data;
                if (success && me.op) {
                    setIsLoading(false);
                    setScadenza(me.scadenza_abbonamento);
                    return; // stay on the page
                } else if (error) {
                    console.log(error);
                }
            }
        } catch (error) {
            console.log(error)
        }
        setIsLoading(false);
        if (process.env.REACT_APP_ENV != 'test') {
            window.location.href = '/login';
        }
    }

    const loadUsers = async (p: number, q: string) => {
        try {
            const res = await axios.get(`/api/clienti?page=${p}${q ? '&q=' + q : ""}`, { headers: utils.getAuthHeaders() });
            const { success, error } = res.data;
            if (success) {
                setUsers(res.data.users);
                setUserTotal(res.data.total);
            } else {
                setStatusMessageUsers(error);
            }
        } catch (error) {
            setStatusMessageUsers("Si è verificato un errore imprevisto sul nostro server.");
            console.log(error)
        }
        setUserLoading(false);
    }

    const loadCalendar = async (p: number) => {
        try {
            const res = await axios.get(`/api/calendario?page=${p}`, { headers: utils.getAuthHeaders() });
            const { success, error } = res.data;
            if (success) {
                setCalendar(res.data.calendario);
                setIndexOre([...Array(res.data.calendario[0].ore.length).keys()]);
                if (res.data.calendario[0].ore.length == 0) {
                    setStatusMessageCalendar("Per visualizzare la tua agenda, clicca su Account in alto a destra e imposta i tuoi orari di lavoro.")
                }
            } else {
                setStatusMessageCalendar(error);
            }
        } catch (error) {
            setStatusMessageCalendar("Si è verificato un errore imprevisto sul nostro server.");
            console.log(error)
        }
        setCalendarLoading(false);
    }

    const handleUserSearch = (e: any) => {
        setUserPage(0);
        setUserSearch(e.target.value);
        setUserLoading(true);
        loadUsers(0, e.target.value);
    }

    const handleUserPage = (forward: boolean) => {
        if (userPage == 0 && !forward) {
            return;
        }
        if (5 * (userPage + 1) >= userTotal && forward) {
            return;
        }
        const newPage = userPage + (forward ? 1 : -1)
        setUserPage(newPage);
        setUserLoading(true);
        loadUsers(newPage, userSearch);
    }

    const handleCalendarPage = (forward: boolean) => {
        if (calendarPage == 0 && !forward) {
            return;
        }
        if (calendarPage == 11 && forward) {
            return;
        }
        const newPage = calendarPage + (forward ? 1 : -1)
        setCalendarPage(newPage);
        setCalendarLoading(true);
        loadCalendar(newPage);
    }

    // 0: out of range
    // 1: busy
    // 2: free
    // 3: booked
    const bgButton = [undefined, "#e57373", "#81c784", '#ffb74d'];

    const handleClose = () => {
        setUserToDelete({ ...userToDelete, id: 0 });
        setOpen(false);
    };

    const handleConfirmDelete = () => {
        deleteUser(userToDelete.id);
        setUserToDelete({ ...userToDelete, id: 0 });
        setOpen(false);
    };

    const openDeleteDialog = (e: any, user: any) => {
        e.stopPropagation();
        setUserToDelete(user);
        setOpen(true);
    }

    const handleClose1 = () => {
        setOpen1(false);
    };

    const handleConfirmSetFree = () => {
        setBookingState(selectedSpot.i, selectedSpot.j, selectedSpot.o, 2);
        setOpen1(false);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    const handleConfirmSetBusy = () => {
        setBookingState(selectedSpot.i, selectedSpot.j, selectedSpot.o, 1);
        setOpen2(false);
    };

    const handleClose3 = () => {
        setOpen3(false);
    };

    const handleConfirmBookingDeletion = () => {
        setBookingState(selectedSpot.i, selectedSpot.j, selectedSpot.o, 2);
        setOpen4(false);
    };

    const handleClose4 = () => {
        setOpen4(false);
    };

    const handleAskForBookingDeletion = () => {
        setOpen3(false);
        setOpen4(true);
    };

    const manageOra = (i: number, j: number, o: number) => {
        setSelectedSpot({ i, j, o });
        if (calendar[i].ore[j][o].stato == 1) {
            setOpen1(true);
        } else if (calendar[i].ore[j][o].stato == 2) {
            setOpen2(true);
        } else if (calendar[i].ore[j][o].stato == 3) {
            setOpen3(true);
        } else {
            setSelectedSpot({ i: 0, j: 0, o: 0 });
        }
    }

    const deleteUser = async (index: number) => {
        try {
            if (userLoading) {
                return;
            }
            setStatusMessageUsers("");
            setUserLoading(true);
            const res = await axios.post('/api/deletecliente', { id: index }, { headers: utils.getAuthHeaders() });
            const { success, error } = res.data;
            if (success) {
                setUserPage(0);
                loadUsers(0, userSearch);
            } else {
                setUserLoading(false);
                setStatusMessageUsers(error);
            }
        } catch (error) {
            setUserLoading(false);
            setStatusMessageUsers("Si è verificato un errore imprevisto sul nostro server.");
            console.log(error)
        }
    };

    const setBookingState = async (i: number, j: number, o: number, state: number) => {
        try {
            if (calendarLoading) {
                return;
            }
            setStatusMessageCalendar("");
            setCalendarLoading(true);
            const giorno = calendar[i].isoDate;
            const ora = `${calendar[i].ore[j][o].ora.substring(0, 2)}${calendar[i].ore[j][o].ora.substring(3, 5)}`;
            const res = await axios.post('/api/updateprenotazione', { state, ora, giorno }, { headers: utils.getAuthHeaders() });
            const { success, error } = res.data;
            if (success) {
                loadCalendar(calendarPage);
            } else {
                setCalendarLoading(false);
                setStatusMessageCalendar(error);
            }
        } catch (error) {
            setCalendarLoading(false);
            setStatusMessageCalendar("Si è verificato un errore imprevisto sul nostro server.");
            console.log(error)
        }
    }

    const confirmUser = async (index: number) => {
        try {
            if (userLoading) {
                return;
            }
            setStatusMessageUsers("");
            setUserLoading(true);
            const res = await axios.post('/api/confirmcliente', { id: index }, { headers: utils.getAuthHeaders() });
            const { success, error } = res.data;
            if (success) {
                loadUsers(userPage, userSearch);
            } else {
                setUserLoading(false);
                setStatusMessageUsers(error);
            }
        } catch (error) {
            setUserLoading(false);
            setStatusMessageUsers("Si è verificato un errore imprevisto sul nostro server.");
            console.log(error)
        }

    };

    const openCustomer = (id: number) => {
        return function () {
            window.location.href = `/op/customer?id=${id}`;
        }
    }

    return (
        <div>
            <Navbar logged={true} isOp={true} />
            {isLoading ? (<Stack sx={{ mt: 5 }} alignItems='center'><CircularProgress disableShrink /></Stack>) : (
                <Container>
                    <Paper sx={{ px: 3, pt: 3, pb: 2, mt: 5, backgroundColor: '#282520' }}>
                        <Typography variant="h4" color="#ECE9E6" gutterBottom sx={{ pl: 1, mb: 2 }}>
                            I tuoi Clienti
                        </Typography>
                        <div style={{ display: 'flex' }}>
                            <TextField
                                id="outlined-size-small"
                                size="small"
                                placeholder='Cerca...'
                                onChange={handleUserSearch}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{ flexGrow: 1 }}
                            />
                        </div>
                        {userLoading ? (<Stack sx={{ my: 3 }} alignItems='center'><CircularProgress disableShrink /></Stack>) :
                            (<div>
                                <TableContainer sx={{ width: '100%', overflowX: 'auto' }}>
                                    <Table size='small' sx={{ mt: 3 }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: "rgba(255, 255, 255, 0.4)" }}>COGNOME</TableCell>
                                                <TableCell sx={{ color: "rgba(255, 255, 255, 0.4)" }}>NOME</TableCell>
                                                <TableCell sx={{ color: "rgba(255, 255, 255, 0.4)" }}>EMAIL</TableCell>
                                                <TableCell sx={{ color: "rgba(255, 255, 255, 0.4)" }}>TELEFONO</TableCell>
                                                <TableCell align='center' sx={{ color: "rgba(255, 255, 255, 0.4)" }}>ACCETTA</TableCell>
                                                <TableCell align='center' sx={{ color: "rgba(255, 255, 255, 0.4)" }}>ELIMINA</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {users.map(user => {
                                                if (user.confermato) {
                                                    return (<ButtonBase onClick={openCustomer(user.id)} key={user.id} component={TableRow} sx={dataRowSX}>
                                                        <TableCell>{user.cognome}</TableCell>
                                                        <TableCell>{user.nome}</TableCell>
                                                        <TableCell>{user.email}</TableCell>
                                                        <TableCell>{user.telefono}</TableCell>
                                                        <TableCell>
                                                        </TableCell>
                                                        <TableCell align='center'>
                                                            <IconButton sx={{ p: 0 }} onMouseDown={mouseDown} onClick={(e) => openDeleteDialog(e, user)}>
                                                                <DeleteOutline color='error' />
                                                            </IconButton>
                                                        </TableCell>
                                                    </ButtonBase>)
                                                } else {
                                                    return (<TableRow>
                                                        <TableCell>{user.cognome}</TableCell>
                                                        <TableCell>{user.nome}</TableCell>
                                                        <TableCell>{user.email}</TableCell>
                                                        <TableCell>{user.telefono}</TableCell>
                                                        <TableCell align='center'>
                                                            <IconButton sx={{ p: 0 }} onMouseDown={mouseDown} onClick={() => confirmUser(user.id)}>
                                                                <CheckCircleOutline color='success' />
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell align='center'>
                                                            <IconButton sx={{ p: 0 }} onMouseDown={mouseDown} onClick={(e) => openDeleteDialog(e, user)}>
                                                                <DeleteOutline color='error' />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>)
                                                }
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Stack direction='row' alignItems='center' sx={{ pt: 1 }}>
                                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
                                    <Typography fontSize='1.1rem' sx={{ pr: 2, color: "rgba(255, 255, 255, 0.8)" }}>
                                        {`${userPage * 5 + 1} - ${userPage * 5 + users.length} di ${userTotal}`}
                                    </Typography>
                                    <IconButton onClick={() => handleUserPage(false)} disabled={userPage == 0}><ArrowBackIos /></IconButton>
                                    <IconButton onClick={() => handleUserPage(true)} disabled={5 * (userPage + 1) >= userTotal}><ArrowForwardIos /></IconButton>
                                </Stack>
                            </div>)}
                        {statusMessageUsers && (
                            <Paper variant='outlined' sx={{
                                p: 2, my: 1, flexGrow: 1,
                                backgroundColor: "#e57373",
                                borderColor: '#d32f2f',
                                color: 'black'
                            }} role="alert">
                                {statusMessageUsers}
                            </Paper>
                        )}
                    </Paper>
                    <Paper sx={{ px: 3, pt: 3, pb: 2, mt: 3, mb: 2, backgroundColor: '#282520' }}>
                        <Typography variant="h4" color="#ECE9E6" gutterBottom sx={{ pl: 1, mb: 1 }}>
                            La tua agenda
                        </Typography>
                        {scadenza ? <Typography
                            variant='h6'
                            align='center'
                            color={scadenza < now ? bgButton[1] : (scadenza < now + 7 * 24 * 60 * 60 * 1000 ? bgButton[3] : bgButton[2])}
                        >
                            {scadenza < now ?
                                `Abbonamento scaduto il ${new Date(scadenza).toLocaleDateString()}!` :
                                (scadenza < now + 7 * 24 * 60 * 60 * 1000 ?
                                    `Abbonamento in scadenza il ${new Date(scadenza).toLocaleDateString()}` :
                                    `Abbonamento valido fino al ${new Date(scadenza).toLocaleDateString()}`)}
                        </Typography> : <Typography />}
                        {scadenza && scadenza < now + 7 * 24 * 60 * 60 * 1000 ?
                            <Box textAlign="center">
                                <Button variant='contained' href='/subscription' sx={{ fontWeight: "bold" }}>Rinnova il tuo abbonamento</Button>
                            </Box> : <Typography />}
                        {calendarLoading ? (<Stack sx={{ my: 3 }} alignItems='center'><CircularProgress disableShrink /></Stack>) :
                            (<div>
                                <TableContainer sx={{ width: '100%', overflowX: 'auto', mt: 1 }}>
                                    <Table size='small'>
                                        <TableHead>
                                            <TableRow>
                                                {calendar.map(day => (
                                                    <TableCell align='center' sx={{ color: "rgba(255, 255, 255, 0.4)" }}>{day.data}</TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {indexOre.map(j => (<TableRow>
                                                {indexCalendar.map(i => (
                                                    <TableCell sx={{ px: 0 }} align='center'>
                                                        <Stack direction='row' justifyContent='center' spacing={0.5}>
                                                            <Button
                                                                sx={{ backgroundColor: bgButton[calendar[i].ore[j][0].stato] }}
                                                                variant='contained'
                                                                disabled={calendar[i].ore[j][0].stato == 0}
                                                                onClick={() => manageOra(i, j, 0)}>{calendar[i].ore[j][0].ora}
                                                            </Button>
                                                            <Button
                                                                sx={{ backgroundColor: bgButton[calendar[i].ore[j][1].stato] }}
                                                                variant='contained'
                                                                disabled={calendar[i].ore[j][1].stato == 0}
                                                                onClick={() => manageOra(i, j, 1)}>{calendar[i].ore[j][1].ora}
                                                            </Button>
                                                        </Stack>
                                                    </TableCell>
                                                ))}
                                            </TableRow>)
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Stack direction='row' alignItems='center' sx={{ pt: 1 }}>
                                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
                                    <Typography fontSize='1.1rem' sx={{ pr: 2, color: "rgba(255, 255, 255, 0.8)" }}>
                                        {`${calendarPage * 7 + 1} - ${calendarPage * 7 + 7} di ${7 * 12}`}
                                    </Typography>
                                    <IconButton onClick={() => handleCalendarPage(false)} disabled={calendarPage == 0}><ArrowBackIos /></IconButton>
                                    <IconButton onClick={() => handleCalendarPage(true)} disabled={calendarPage == 11}><ArrowForwardIos /></IconButton>
                                </Stack>
                            </div>)
                        }
                        {statusMessageCalendar && (
                            <Paper variant='outlined' sx={{
                                p: 2, my: 1, flexGrow: 1,
                                backgroundColor: "#e57373",
                                borderColor: '#d32f2f',
                                color: 'black'
                            }} role="alert">
                                {statusMessageCalendar}
                            </Paper>
                        )}
                    </Paper>
                    <Container sx={{ textAlign: "center" }}>
                        <ButtonBase onClick={() => setOpen5(true)}>
                            <Typography variant="h6" color="#ECE9E6" gutterBottom sx={{ pb: 2, pl: 1 }}>
                                Hai qualche dubbio su come utilizzare MyExperts? <span style={{ color: "#E5C699" }}>Guarda il tutorial</span>!
                            </Typography>
                        </ButtonBase>
                    </Container>
                </Container >)}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                sx={{ p: 2 }}
                PaperProps={{
                    style: {
                        backgroundColor: '#282520',
                    },
                }}
            >
                <DialogTitle id="dialog-title">
                    {`Rimuovere il cliente ${userToDelete.cognome} ${userToDelete.nome}?`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-description">
                        Questa operazione eliminerà tutti i documenti inviati e ricevuti!
                        Per rientrare tra i tuoi clienti, dovrà riaggiungerti alla lista dei suoi professionisti.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Annulla</Button>
                    <Button color='error' onClick={handleConfirmDelete}>Elimina</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open1}
                onClose={handleClose1}
                aria-labelledby="dialog-title-1"
                aria-describedby="dialog-description-1"
                sx={{ p: 2 }}
                PaperProps={{
                    style: {
                        backgroundColor: '#282520',
                    },
                }}
            >
                <DialogTitle id="dialog-title-1">
                    {`Giorno ${calendar.length && calendar[0].ore.length ? calendar[selectedSpot.i].data : ""}, ore ${calendar.length && calendar[0].ore.length ? calendar[selectedSpot.i].ore[selectedSpot.j][selectedSpot.o].ora : ""}`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-description-1">
                        Hai segnato quest'ora come non disponibile. Vuoi renderla prenotabile?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose1}>Annulla</Button>
                    <Button onClick={handleConfirmSetFree}>Conferma</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open2}
                onClose={handleClose2}
                aria-labelledby="dialog-title-2"
                aria-describedby="dialog-description-2"
                sx={{ p: 2 }}
                PaperProps={{
                    style: {
                        backgroundColor: '#282520',
                    },
                }}
            >
                <DialogTitle id="dialog-title-2">
                    {`Giorno ${calendar.length && calendar[0].ore.length ? calendar[selectedSpot.i].data : ""}, ore ${calendar.length && calendar[0].ore.length ? calendar[selectedSpot.i].ore[selectedSpot.j][selectedSpot.o].ora : ""}`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-description-2">
                        Quest'ora al momento è disponibile. Vuoi renderla non prenotabile?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose2}>Annulla</Button>
                    <Button onClick={handleConfirmSetBusy}>Conferma</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open3}
                onClose={handleClose3}
                aria-labelledby="dialog-title-3"
                aria-describedby="dialog-description-3"
                sx={{ p: 2 }}
                PaperProps={{
                    style: {
                        backgroundColor: '#282520',
                    },
                }}
            >
                <DialogTitle id="dialog-title-3">
                    {`Giorno ${calendar.length && calendar[0].ore.length ? calendar[selectedSpot.i].data : ""}, ore ${calendar.length && calendar[0].ore.length ? calendar[selectedSpot.i].ore[selectedSpot.j][selectedSpot.o].ora : ""}`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-description-3">
                        {calendar.length && calendar[0].ore.length ? `Quest'ora al momento è prenotata da ` +
                            `${calendar[selectedSpot.i].ore[selectedSpot.j][selectedSpot.o].nome} ` +
                            `${calendar[selectedSpot.i].ore[selectedSpot.j][selectedSpot.o].cognome} per il servizio ` +
                            `${calendar[selectedSpot.i].ore[selectedSpot.j][selectedSpot.o].servizio}. ` +
                            `Vuoi eliminare questa prenotazione? Il cliente riceverà una email di avvenuta cancellazione.` : ""}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose3}>Annulla</Button>
                    <Button color='error' onClick={handleAskForBookingDeletion}>Elimina</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open4}
                onClose={handleClose4}
                aria-labelledby="dialog-title-4"
                aria-describedby="dialog-description-4"
                sx={{ p: 2 }}
                PaperProps={{
                    style: {
                        backgroundColor: '#282520',
                    },
                }}
            >
                <DialogTitle id="dialog-title-4">
                    {`Eliminare prenotazione per il ${calendar.length && calendar[0].ore.length ? calendar[selectedSpot.i].data : ""} alle ${calendar.length && calendar[0].ore.length ? calendar[selectedSpot.i].ore[selectedSpot.j][selectedSpot.o].ora : ""}?`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-description-4">
                        {calendar.length && calendar[0].ore.length ? `Sei sicuro di voler eliminare la prenotazione di ` +
                            `${calendar[selectedSpot.i].ore[selectedSpot.j][selectedSpot.o].nome} ` +
                            `${calendar[selectedSpot.i].ore[selectedSpot.j][selectedSpot.o].cognome} per il servizio ` +
                            `${calendar[selectedSpot.i].ore[selectedSpot.j][selectedSpot.o].servizio}?` : ""}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose4}>Annulla</Button>
                    <Button color='error' onClick={handleConfirmBookingDeletion}>Elimina</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open5}
                onClose={() => setOpen5(false)}
                fullWidth={true}
                maxWidth='lg'
                aria-labelledby="dialog-title-1"
                sx={{ p: 2 }}
                PaperProps={{
                    style: {
                        backgroundColor: '#282520',
                    },
                }}
            >
                <DialogTitle align='center' fontSize='1.6em' id="dialog-title-1">
                    Tutorial Account Professionista
                </DialogTitle>
                <DialogContent sx={{ pb: 0 }}>
                    <Youtube isOp={true} title="Tutorial" />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen5(false)}>Chiudi</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default OpHome;
