import axios from 'axios';
import io from 'socket.io-client';
import { useState, useEffect, useRef, ChangeEvent } from 'react';
import { useTheme, useMediaQuery, Container, Typography, TextField, ButtonBase, Grid, Stack, Table, TableHead, TableRow, TableCell, TableBody, InputAdornment, Paper, SxProps, IconButton, Tooltip, Box, List, Divider, ToggleButtonGroup, ToggleButton, Button, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, TableContainer, InputLabel, Select, MenuItem, CircularProgress, ListItem, ListItemButton } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos, DeleteOutline, KeyboardArrowDown, KeyboardArrowUp, Search, Send, UploadFile } from '@mui/icons-material';


import Navbar from './Navbar';
import utils from './utils';
import { LoadingButton } from '@mui/lab';

type User = {
    id: number;
    nome: string;
    cognome: string;
    email: string;
    pec: string;
    telefono: string;
    codiceFiscale: string;
    partitaIva: string;
    nomeAzienda: string;
    indirizzo: null;
    citta: string;
    cap: string;
    provincia: string;
    note: string;
    appuntamento: string | undefined,
};

// message type: 0: op, 1: user, 2: divider
type Message = {
    index: number;
    tipo: number;
    testo: string;
    ora: string;
}

type Document = {
    id: string;
    data: string;
    file: string;
    size: string;
}

const dataRowSX: SxProps = {
    display: "table-row",
    ":hover": {
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        cursor: "pointer",
    },
};

const indexCalendar = [0, 1, 2, 3, 4, 5, 6];

const formatOra = (ts: number) => {
    let date = new Date(ts).toLocaleDateString()
    const now = new Date().toLocaleDateString()
    const showDay = date != now;
    if (date.split('/')[2] == now.split('/')[2]) {
        date = date.substring(0, date.length - 5);
    }
    const str = new Date(ts).toLocaleTimeString();
    let addHour = 0;
    if (str.includes('AM') && str.substring(0, 2) == '12') {
        addHour = -12;
    } else if (str.includes('PM') && str.substring(0, 2) != '12') {
        addHour = 12;
    }
    const hours = Number(str.split(':')[0]) + addHour;
    const minutes = str.split(':')[1].substring(0, 2);
    return `${hours}:${minutes}` + (showDay ? ` (${date})` : "");
}

const mouseDown = (e: any) => {
    e.stopPropagation();
}

const Main = (props: { isOp: boolean }) => {
    const [alignment, setAlignment] = useState('ricevuti');
    const [open, setOpen] = useState(false);
    const [fileToDelete, setFileToDelete] = useState({ id: "", file: "" });

    // user + title
    const [isLoading, setIsLoading] = useState(true);
    const [statusMessageUser, setStatusMessageUser] = useState("");

    const [user, setUser] = useState({} as User);

    // chat
    const [messages, setMessages] = useState([] as Message[]);
    const [messagesLoaded, setMessagesLoaded] = useState([] as Message[]);
    const [message, setMessage] = useState("");
    const [isMessageLoading, setMessageLoading] = useState(false);
    const [isChatLoading, setChatLoading] = useState(false);
    const [isBatchFullyRendered, setBatchFullyRendered] = useState(true);
    const [isFullChatLoaded, setFullChatLoaded] = useState(false);
    const [isIntersecting, setIsIntersecting] = useState(false);
    const [statusMessageChat, setStatusMessageChat] = useState("");
    const [messagePivot, setMessagePivot] = useState(0);

    // docs
    const [docs, setDocs] = useState([] as Document[]);
    const [docSearch, setDocSearch] = useState("");
    const [docTotal, setDocTotal] = useState(0);
    const [docPage, setDocPage] = useState(0);
    const [docFile, setDocFile] = useState<File | null>(null);
    const [filename, setFilename] = useState("");
    const [isErrorDoc, setIsErrorDoc] = useState(false);
    const [statusMessageDoc, setStatusMessageDoc] = useState("");
    const [isLoadingDoc, setIsLoadingDoc] = useState(true);

    // collapse
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);

    // bookings
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [open6, setOpen6] = useState(false);
    const [open7, setOpen7] = useState(false);
    const [open8, setOpen8] = useState(false);
    const [selectedSpot, setSelectedSpot] = useState({ i: 0, j: 0, o: 0 });
    const [selectedService, setSelectedService] = useState({ id: 0, nome: "", note: "" });

    const [calendar, setCalendar] = useState([] as any[]);
    const [indexOre, setIndexOre] = useState([] as number[]);
    const [calendarPage, setCalendarPage] = useState(0);
    const [calendarLoading, setCalendarLoading] = useState(true);
    const [statusMessageCalendar, setStatusMessageCalendar] = useState('');
    const [isErrorCalendar, setIsErrorCalendar] = useState(false);
    const [calendarIsSetByOp, setCalendarIsSetByOp] = useState(false);
    const [servizi, setServizi] = useState([] as any[]);

    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

    const logged = true;

    const urlParams = new URLSearchParams(window.location.search);
    const id = Number(urlParams.get('id') || 0);

    const chatRef = useRef<HTMLDivElement>(null);
    const observerTarget = useRef<HTMLDivElement>(null);

    const socketRef = useRef<any>(null);

    useEffect(() => {
        loadUser();
        const tipo = (Number(alignment == 'inviati') + Number(props.isOp)) % 2;
        loadDocs(0, "", tipo);
        loadMessages();
        if (!props.isOp) {
            loadServices();
            loadCalendar(0, null);
        }

        if (!socketRef.current) {
            socketRef.current = io('/', utils.getSocketParams(id));
            socketRef.current.on('invalid_jwt', () => {
                window.location.href = '/login';
            });
        }
        return () => {
            socketRef.current?.disconnect();
        };
    }, []);

    // need to get UPDATED messages
    useEffect(() => {
        if (socketRef.current) {
            socketRef.current.on('new_message', (msg: any) => {
                setMessages([...messages, msg.message]);
                setTimeout(() => {
                    chatRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
                    setBatchFullyRendered(true);
                }, 100);
            });
        }
    }, [messages]);

    useEffect(() => {
        if (isMdUp) {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    setIsIntersecting(entry.isIntersecting);
                }
            );
            observer.observe(observerTarget.current!);

            return () => observer.disconnect();
        } else if (open2) {
            setTimeout(() => {
                const observer = new IntersectionObserver(
                    ([entry]) => {
                        setIsIntersecting(entry.isIntersecting);
                    }
                );
                observer.observe(observerTarget.current!);

                return () => observer.disconnect();
            }, 500);
        }
    }, [isIntersecting, open2]);

    useEffect(() => {
        if (open2) {
            chatRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [open2]);

    useEffect(() => {
        if (isIntersecting) {
            addMessage();
        }
    }, [isIntersecting]);

    const addMessage = async () => {
        if (!messagePivot) {
            return;
        }
        if (messagesLoaded.length) {
            const array = [...messagesLoaded];
            const item = array.pop()!;
            setMessagesLoaded(array);
            if (!array.length) {
                setBatchFullyRendered(true);
            }
            setMessages([item, ...messages]);
        } else {
            await loadMessages();
        }
        setTimeout(() => {
            setIsIntersecting(false)
        }, 200);
    }

    const loadUser = async () => {
        try {
            const res = await axios.get(`/api/${props.isOp ? 'cliente' : 'op'}?id=${id}`, { headers: utils.getAuthHeaders(), validateStatus: () => true });
            if (res.status == 200) {
                const { success, user } = res.data;
                if (success) {
                    setUser(user);
                } else {
                    window.location.href = props.isOp ? '/op' : '/user';
                }
            } else if (res.status == 403) {
                if (process.env.REACT_APP_ENV != 'test') {
                    window.location.href = '/login';
                }
            } else {
                console.error(res);
                throw Error("");
            }
        } catch (_) {
            setStatusMessageUser("Si è verificato un errore imprevisto sul nostro server.");
        }
        setIsLoading(false);
    }

    const loadCalendar = async (p: number, successMessage: string | null) => {
        try {
            const res = await axios.get(`/api/calendariocliente?id=${id}&page=${p}`, { headers: utils.getAuthHeaders() });
            const { success, error } = res.data;
            if (success) {
                if (successMessage) {
                    setIsErrorCalendar(false);
                    setStatusMessageCalendar(successMessage)
                }
                setCalendar(res.data.calendario);
                setIndexOre([...Array(res.data.calendario[0].ore.length).keys()]);
                if (res.data.calendario[0].ore.length == 0) {
                    setCalendarIsSetByOp(false);
                    setIsErrorCalendar(true);
                    setStatusMessageCalendar("Non è possibile creare prenotazioni, questo professionista non ha ancora inserito i propri orari.");
                } else {
                    setCalendarIsSetByOp(true);
                }
            } else {
                setIsErrorCalendar(true);
                setStatusMessageCalendar(error);
            }
        } catch (error) {
            setIsErrorCalendar(true);
            setStatusMessageCalendar("Si è verificato un errore imprevisto sul nostro server.");
            console.log(error)
        }
        setCalendarLoading(false);
    }

    const loadServices = async () => {
        try {
            const res = await axios.get(`/api/servizi?id=${id}&all=true`, { headers: utils.getAuthHeaders() });
            const { success, services } = res.data;
            if (success) {
                const reordered: any[] = [];
                let altroService;
                for (const s of services) {
                    if (s.nome == 'Altro (Specificare)') {
                        altroService = s;
                    } else {
                        reordered.push(s);
                    }
                }
                reordered.push(altroService);
                setServizi(reordered);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const loadMessages = async () => {
        if (isChatLoading || isFullChatLoaded || !isBatchFullyRendered) {
            return;
        }
        setChatLoading(true);
        try {
            const res = await axios.get(`/api/messaggi?id=${id}${messagePivot ? "&pivot=" + messagePivot : ""}`, { headers: utils.getAuthHeaders() });
            const { success, messages: newMessages, error } = res.data;
            if (success) {
                const mappedMessages = newMessages.map((m: any) => { return { ...m, ora: formatOra(m.timestamp) } });
                if (!messagePivot) {
                    setMessages(mappedMessages);
                    setTimeout(() => {
                        chatRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
                        setBatchFullyRendered(true);
                    }, 100);
                } else if (newMessages.length) {
                    const item = mappedMessages.pop();
                    setMessagesLoaded(mappedMessages);
                    setMessages([item, ...messages]);
                }
                if (newMessages.length) {
                    setMessagePivot(newMessages[0].index);
                }
                if (newMessages.length < 12) {
                    setFullChatLoaded(true);
                }
            } else {
                setStatusMessageChat(error);
                setFullChatLoaded(true);
            }
        } catch (e) {
            console.log(e);
            setStatusMessageChat("Si è verificato un errore imprevisto sul nostro server.");
            setFullChatLoaded(true);
        }
        setChatLoading(false);
    }

    const updateUser = async (e: any) => {
        e.preventDefault();
        try {
            setStatusMessageUser("");
            setIsLoading(true);
            const res = await axios.post('/api/updatecliente', { id, note: user.note }, { headers: utils.getAuthHeaders() });
            const { success, error } = res.data;
            if (success) {
                loadUser();
            } else {
                setIsLoading(false);
                setStatusMessageUser(error);
            }
        } catch (error) {
            setIsLoading(false);
            setStatusMessageUser("Si è verificato un errore imprevisto sul nostro server.");
            console.log(error)
        }
    };

    const sendMessage = async () => {
        try {
            setMessageLoading(true);
            setStatusMessageChat("");
            const res = await axios.post('/api/inviamessaggio', { id, testo: message }, { headers: utils.getAuthHeaders() });
            const { success, error } = res.data;
            if (success) {
                setMessage("");
            } else {
                setStatusMessageChat(error);
            }
        } catch (error) {
            setStatusMessageChat("Si è verificato un errore imprevisto sul nostro server.");
            console.log(error)
        }
        setMessageLoading(false);
    };

    // 0: out of range
    // 1: busy
    // 2: free
    // 3: booked
    // 4: booked by ME
    const bgButton = [undefined, "#e57373", "#81c784", "#e57373", '#ffb74d'];

    const handleCalendarPage = (forward: boolean) => {
        if (calendarPage == 0 && !forward) {
            return;
        }
        if (calendarPage == 11 && forward) {
            return;
        }
        const newPage = calendarPage + (forward ? 1 : -1)
        setCalendarPage(newPage);
        setCalendarLoading(true);
        loadCalendar(newPage, null);
    }

    const handleAlignment = (
        _: any,
        newAlignment: string,
    ) => {
        setAlignment(newAlignment);
        const tipo = (Number(newAlignment == 'inviati') + Number(props.isOp)) % 2;
        loadDocs(0, "", tipo);
    };

    const handleClose = () => {
        setFileToDelete({ id: "", file: "" });
        setOpen(false);
    };

    const handleConfirmDelete = () => {
        deleteDoc(fileToDelete.id);
        setFileToDelete({ id: "", file: "" });
        setOpen(false);
    };

    const openDeleteDialog = (e: any, doc: any) => {
        e.stopPropagation();
        setFileToDelete(doc);
        setOpen(true);
    }

    const openCalendar = () => {
        setStatusMessageCalendar("");
        setOpen4(true);
    }

    const manageOra = (i: number, j: number, o: number) => {
        setSelectedSpot({ i, j, o });
        if (calendar[i].ore[j][o].stato == 2) {
            setOpen5(true);
        } else if (calendar[i].ore[j][o].stato == 4) {
            setOpen6(true);
        } else {
            setSelectedSpot({ i: 0, j: 0, o: 0 });
        }
    }

    const handleAskForService = () => {
        // initialize default
        setSelectedService({ id: servizi.length ? servizi[0].id : 0, nome: servizi.length ? servizi[0].nome : "", note: "" });
        setOpen5(false);
        setOpen8(true);
    }

    const handleConfirmBookingDeletion = async () => {
        setOpen7(false);
        try {
            setStatusMessageCalendar("");
            setCalendarLoading(true);
            const giorno = calendar[selectedSpot.i].isoDate;
            const ora = `${calendar[selectedSpot.i].ore[selectedSpot.j][selectedSpot.o].ora.substring(0, 2)}${calendar[selectedSpot.i].ore[selectedSpot.j][selectedSpot.o].ora.substring(3, 5)}`;
            const res = await axios.post('/api/deleteprenotazione', { id, ora, giorno }, { headers: utils.getAuthHeaders() });
            const { success, error } = res.data;
            if (success) {
                loadCalendar(calendarPage, "Prenotazione eliminata con successo.");
            } else {
                setCalendarLoading(false);
                setIsErrorCalendar(true);
                setStatusMessageCalendar(error);
            }
        } catch (error) {
            setCalendarLoading(false);
            setIsErrorCalendar(true);
            setStatusMessageCalendar("Si è verificato un errore imprevisto sul nostro server.");
            console.log(error)
        }
    };

    const handleAskForBookingDeletion = () => {
        setOpen6(false);
        setOpen7(true);
    };

    const handleBookingConfirm = async () => {
        setOpen8(false);
        try {
            setStatusMessageCalendar("");
            setCalendarLoading(true);
            const serviceId = selectedService.id;
            const note = selectedService.note;
            const giorno = calendar[selectedSpot.i].isoDate;
            const ora = `${calendar[selectedSpot.i].ore[selectedSpot.j][selectedSpot.o].ora.substring(0, 2)}${calendar[selectedSpot.i].ore[selectedSpot.j][selectedSpot.o].ora.substring(3, 5)}`;
            const res = await axios.post('/api/createprenotazione', { id, ora, giorno, serviceId, note }, { headers: utils.getAuthHeaders() });
            const { success, error } = res.data;
            if (success) {
                loadCalendar(calendarPage, "Prenotazione creata con successo. Riceverai una email coi dettagli.");
                loadUser();
            } else {
                setCalendarLoading(false);
                setIsErrorCalendar(true);
                setStatusMessageCalendar(error);
            }
        } catch (error) {
            setCalendarLoading(false);
            setIsErrorCalendar(true);
            setStatusMessageCalendar("Si è verificato un errore imprevisto sul nostro server.");
            console.log(error)
        }
    }


    // DOCS -------------------------------------------------------------------

    const loadDocs = async (p: number, q: string, tipo: number) => {
        try {
            const res = await axios.get(`/api/documenti?id=${id}&t=${tipo}&page=${p}${q ? '&q=' + q : ""}`, { headers: utils.getAuthHeaders() });
            const { success, error } = res.data;
            if (success) {
                setDocs(res.data.documents.map((d: any) => {
                    return {
                        id: d.id,
                        data: utils.formatData(d.timestamp),
                        file: d.nome,
                        size: d.size_kb,
                    }
                }));
                setDocTotal(res.data.total);
            } else {
                setIsErrorDoc(true);
                setStatusMessageDoc(error);
            }
        } catch (error) {
            setIsErrorDoc(true);
            setStatusMessageDoc("Si è verificato un errore imprevisto sul nostro server.");
            console.log(error)
        }
        setIsLoadingDoc(false);
    }

    const handleDocSearch = (e: any) => {
        setDocPage(0);
        setDocSearch(e.target.value);
        const tipo = (Number(alignment == 'inviati') + Number(props.isOp)) % 2;
        loadDocs(0, e.target.value, tipo);
    }

    const handleDocPage = (forward: boolean) => {
        if (docPage == 0 && !forward) {
            return;
        }
        if (15 * (docPage + 1) >= docTotal && forward) {
            return;
        }
        const newPage = docPage + (forward ? 1 : -1)
        setDocPage(newPage);
        const tipo = (Number(alignment == 'inviati') + Number(props.isOp)) % 2;
        loadDocs(newPage, docSearch, tipo);
    }

    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];
        if (file) {
            setDocFile(file);
            setFilename(file.name);
            setStatusMessageDoc("");
        }
    };

    const cancelUpload = (e: any) => {
        e.preventDefault();
        setDocFile(null);
        setFilename("");
    }

    const confirmUpload = async (e: any) => {
        e.preventDefault();
        setStatusMessageDoc("");
        const payload = new FormData();
        payload.append('doc', docFile as Blob);
        payload.append('data', JSON.stringify({ filename, id }));
        setDocFile(null);
        setFilename("");
        setIsLoadingDoc(true)
        try {
            const res = await axios.post('/api/carica', payload, {
                headers: {
                    "Content-Type": 'multipart/form-data', ...utils.getAuthHeaders()
                }
            });
            const { success, error } = res.data;
            if (success) {
                setIsErrorDoc(false);
                setStatusMessageDoc('Documento inviato correttamente.');
                return handleAlignment(null, "inviati");
            } else {
                setIsErrorDoc(true);
                setStatusMessageDoc(error);
            }
        } catch (error: any) {
            setIsErrorDoc(true);
            if (error.response && error.response.status == 413) {
                setStatusMessageDoc("Il documento è troppo grande. Il limite massimo è di 100 MB.");
            } else {
                setStatusMessageDoc("Si è verificato un errore imprevisto sul nostro server.");
            }
        }
        setIsLoadingDoc(false);
    }

    const downloadDoc = async (id: string) => {
        setStatusMessageDoc("");
        try {
            const response = await axios.get(`/api/scarica?id=${id}`, {
                headers: utils.getAuthHeaders(),
                responseType: 'blob'
            });

            const contentDisposition = response.headers['content-disposition'];
            const filename = contentDisposition
                ? contentDisposition.split(';')[1].split('filename=')[1].trim()
                : 'documento';

            const fileBlob = new Blob([response.data]);

            const fileUrl = URL.createObjectURL(fileBlob);

            const link = document.createElement('a');
            link.href = fileUrl;
            link.download = filename;
            link.click();
            URL.revokeObjectURL(fileUrl);
        } catch (error: any) {
            setIsErrorDoc(true);
            setStatusMessageDoc("Si è verificato un errore imprevisto sul nostro server.");
        }
    }

    const deleteDoc = async (id: string) => {
        setStatusMessageDoc("");
        setIsLoadingDoc(true)
        try {
            const res = await axios.post('/api/deletedocumento', { id }, { headers: utils.getAuthHeaders() });
            const { success, error } = res.data;
            if (success) {
                setIsErrorDoc(false);
                setStatusMessageDoc('Documento eliminato correttamente.');
                setDocPage(0);
                const tipo = (Number(alignment == 'inviati') + Number(props.isOp)) % 2;
                return loadDocs(0, docSearch, tipo);
            } else {
                setIsErrorDoc(true);
                setStatusMessageDoc(error);
            }
        } catch (error: any) {
            setIsErrorDoc(true);
            setStatusMessageDoc("Si è verificato un errore imprevisto sul nostro server.");
        }
        setIsLoadingDoc(false);
    };

    return (
        <div>
            <Navbar logged={logged} isOp={props.isOp} />
            <Container>
                {user.cognome ? (<Typography variant="h3" align='center' color="#ECE9E6" gutterBottom sx={{ p: 1 }}>
                    {`${user.cognome}, ${user.nome} `}
                </Typography>) : (<Typography />)}
            </Container>
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={3} sx={{ flexGrow: 1 }}>
                        <Paper sx={{ p: 3, pb: 1, backgroundColor: '#282520' }}>
                            <Stack direction='row' sx={{ mb: 2 }}>
                                {isMdUp ? (<Typography sx={{ pl: 1 }} />) : (<IconButton
                                    onClick={() => setOpen1(!open1)}
                                    aria-label="expand"
                                    size="small"
                                >
                                    {open1 ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                </IconButton>)}
                                <Typography variant="h5" color="#ECE9E6" gutterBottom sx={{ mb: 0 }}>
                                    {props.isOp ? "Anagrafica" : "Informazioni"}
                                </Typography>
                            </Stack>
                            {isLoading ? (<Stack sx={{ my: 5 }} alignItems='center'><CircularProgress disableShrink /></Stack>) : (<div>
                                {isMdUp || open1 ? (<div>
                                    <Stack style={{ display: 'flex' }}>
                                        <Typography color={user.appuntamento ? "#e57373" : "#E5C699"} sx={{ px: 1, mb: 3 }}>
                                            {user.appuntamento ? `Prossimo appuntamento: ${user.appuntamento}` : "Nessun appuntamento in programma"}
                                        </Typography>
                                        <TextField
                                            disabled
                                            size='small'
                                            label="Cognome"
                                            defaultValue={user.cognome}
                                            sx={{ flexGrow: 1, mb: 2 }}
                                        />
                                        <TextField
                                            disabled
                                            size='small'
                                            label="Nome"
                                            defaultValue={user.nome}
                                            sx={{ flexGrow: 1, mb: 2 }}
                                        />
                                        {props.isOp ? (<TextField
                                            size='small'
                                            disabled
                                            label="Codice Fiscale"
                                            defaultValue={user.codiceFiscale}
                                            sx={{ flexGrow: 1, mb: 2 }}
                                        />) : (<Typography />)}
                                        <TextField
                                            disabled
                                            size='small'
                                            label="Email"
                                            defaultValue={user.email}
                                            sx={{ flexGrow: 1, mb: 2 }}
                                        />
                                        <TextField
                                            disabled
                                            size='small'
                                            label="PEC"
                                            defaultValue={user.pec}
                                            sx={{ flexGrow: 1, mb: 2 }}
                                        />
                                        <Grid container direction='row'>
                                            <Grid item xs={6}>
                                                <TextField
                                                    disabled
                                                    size='small'
                                                    label="Telefono"
                                                    defaultValue={user.telefono}
                                                    sx={{ width: '100%', mb: 2 }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sx={{ pl: 2 }}>
                                                <TextField
                                                    disabled
                                                    size='small'
                                                    label="Partita IVA"
                                                    defaultValue={user.partitaIva}
                                                    sx={{ width: '100%', mb: 2 }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <TextField
                                            disabled
                                            size='small'
                                            label="Nome Azienda"
                                            defaultValue={user.nomeAzienda}
                                            sx={{ flexGrow: 1, mb: 2 }}
                                        />
                                        <TextField
                                            disabled
                                            size='small'
                                            label="Indirizzo"
                                            defaultValue={user.indirizzo}
                                            sx={{ flexGrow: 1, mb: 2 }}
                                        />
                                        <TextField
                                            disabled
                                            size='small'
                                            label="Città"
                                            defaultValue={user.citta}
                                            sx={{ flexGrow: 1, mb: 2 }}
                                        />
                                        <Grid container direction='row'>
                                            <Grid item xs={6}>
                                                <TextField
                                                    disabled
                                                    size='small'
                                                    label="CAP"
                                                    defaultValue={user.cap}
                                                    sx={{ width: '100%', mb: 2 }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sx={{ pl: 2 }}>
                                                <TextField
                                                    disabled
                                                    size='small'
                                                    label="Provincia"
                                                    defaultValue={user.provincia}
                                                    sx={{ width: '100%', mb: 2 }}
                                                />
                                            </Grid>
                                        </Grid>
                                        {props.isOp ? (<TextField
                                            size="small"
                                            label="Note"
                                            defaultValue={user.note}
                                            onChange={e => setUser({ ...user, note: e.target.value })}
                                            multiline
                                            rows={3}
                                            sx={{ flexGrow: 1, mb: 2 }}
                                        />) : (<Typography />)}

                                    </Stack>
                                    {statusMessageUser && (
                                        <Paper variant='outlined' sx={{
                                            p: 2, mb: 1, flexGrow: 1,
                                            backgroundColor: "#e57373",
                                            borderColor: '#d32f2f',
                                            color: 'black'
                                        }} role="alert">
                                            {statusMessageUser}
                                        </Paper>
                                    )}
                                    {props.isOp ? (<Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        onClick={updateUser}
                                        sx={{ backgroundColor: '#53432D', color: '#ECE9E6', mt: 1, mb: 2, fontSize: '110%' }}
                                    >
                                        Aggiorna Informazioni
                                    </Button>) : (<Typography />)}
                                </div>) : (<Typography />)}
                            </div>)}
                        </Paper>
                        {props.isOp ? <Typography /> : <Grid container>
                            <Grid item xs={12} sx={{ mt: 3, mx: 2 }}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    sx={{ backgroundColor: '#53432D', color: '#ECE9E6', fontSize: '110%' }}
                                    onClick={() => openCalendar()}
                                >
                                    Gestisci prenotazioni
                                </Button>
                            </Grid>
                        </Grid>}
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
                        <Paper sx={{ p: 2, pt: 3, backgroundColor: '#282520' }}>
                            <Stack direction='row' sx={{ mb: 2, pl: 1 }}>
                                {isMdUp ? (<Typography sx={{ pl: 1 }} />) : (<IconButton
                                    onClick={() => setOpen2(!open2)}
                                    aria-label="expand"
                                    size="small"
                                >
                                    {open2 ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                </IconButton>)}
                                <Typography variant="h5" color="#ECE9E6" gutterBottom sx={{ mb: 0 }}>
                                    Chat {isChatLoading ? (<CircularProgress size='1rem' sx={{ ml: 1 }} disableShrink />) : (<Typography />)}
                                </Typography>
                            </Stack>
                            {isMdUp || open2 ? (<div>
                                <Paper
                                    elevation={0}
                                    variant='outlined'
                                    sx={{ px: 2, pt: 1, mb: 2, mt: 1, backgroundColor: '#1c1a17', height: '58vh', overflow: 'auto' }}>
                                    <Typography ref={observerTarget} />
                                    <List>
                                        {messages.map(msg => {
                                            if (msg.tipo == 2) {
                                                return (<Divider sx={{ my: 2 }} >{msg.testo}</Divider>)
                                            } else if (msg.tipo + Number(props.isOp) == 1) {
                                                return (<Box display='flex' justifyContent='flex-end' sx={{ py: 1 }}>
                                                    <Paper sx={{ px: 2, py: 1, backgroundColor: '#53432D', flexShrink: 1 }}>
                                                        <Typography align="right">{msg.testo}</Typography>
                                                        <Typography align="right" color='rgba(255,255,255,0.5)'>{msg.ora}</Typography>
                                                    </Paper>
                                                </Box>)
                                            } else {
                                                return (<Box display='flex' justifyContent='flex-start' sx={{ py: 1 }}>
                                                    <Paper sx={{ px: 2, py: 1, backgroundColor: '#282520', flexShrink: 1 }}>
                                                        <Typography align="left">{msg.testo}</Typography>
                                                        <Typography align="left" color='rgba(255,255,255,0.5)'>{msg.ora}</Typography>
                                                    </Paper>
                                                </Box>)
                                            }
                                        })
                                        }
                                    </List>
                                    <Typography ref={chatRef} />
                                </Paper>
                                {statusMessageChat && (
                                    <Paper variant='outlined' sx={{
                                        p: 2, mb: 2, flexGrow: 1,
                                        backgroundColor: "#e57373",
                                        borderColor: '#d32f2f',
                                        color: 'black'
                                    }} role="alert">
                                        {statusMessageChat}
                                    </Paper>
                                )}
                                <Stack direction='row'>
                                    <TextField
                                        id="outlined-size-small"
                                        size="small"
                                        value={message}
                                        onChange={e => setMessage(e.target.value)}
                                        placeholder='Scrivi un messaggio...'
                                        sx={{ flexGrow: 1, mr: 2 }}
                                    />
                                    <LoadingButton
                                        onClick={sendMessage}
                                        disabled={!message}
                                        endIcon={<Send />}
                                        loading={isMessageLoading}
                                        loadingPosition="end"
                                        variant="contained"
                                    >
                                        <span>Invia</span>
                                    </LoadingButton>
                                </Stack>
                            </div>) : (<Typography />)}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Paper sx={{ px: 3, pt: 3, pb: 2, backgroundColor: '#282520' }}>
                            <Grid container sx={{ mb: 1 }}>
                                <Grid item xs>
                                    <Stack direction='row'>
                                        {isMdUp ? (<Typography sx={{ pl: 1 }} />) : (<IconButton
                                            onClick={() => setOpen3(!open3)}
                                            aria-label="expand"
                                            size="small"
                                        >
                                            {open3 ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                        </IconButton>)}
                                        <Typography variant="h5" color="#ECE9E6" gutterBottom>
                                            Documenti
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item sx={{ pr: 1 }}>
                                    {isMdUp || open3 ? (<ToggleButtonGroup
                                        value={alignment}
                                        exclusive
                                        onChange={handleAlignment}
                                        aria-label="Ricevuti/Inviati"
                                    >
                                        <ToggleButton sx={{ py: 1, px: 2 }} value="ricevuti" aria-label="Ricevuti">
                                            <span>Ricevuti</span>
                                        </ToggleButton>
                                        <ToggleButton sx={{ py: 1, px: 2 }} value="inviati" aria-label="Inviati">
                                            <span>Inviati</span>
                                        </ToggleButton>
                                    </ToggleButtonGroup>) : (<Typography />)}
                                </Grid>
                            </Grid>
                            {isMdUp || open3 ? (<div>
                                <div style={{ display: 'flex' }}>
                                    <TextField
                                        id="outlined-size-small"
                                        size="small"
                                        onChange={handleDocSearch}
                                        placeholder='Cerca...'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search />
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{ flexGrow: 1, mt: 1 }}
                                    />
                                </div>
                                <Table size='small' sx={{ mt: 2 }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ color: "rgba(255, 255, 255, 0.4)", pl: 1, pr: 2 }}>DATA</TableCell>
                                            <TableCell sx={{ color: "rgba(255, 255, 255, 0.4)", px: 0 }}>FILE</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {docs.map(doc => (
                                            <Tooltip title={doc.file} arrow>
                                                <ButtonBase key={doc.id} component={TableRow} sx={dataRowSX} onClick={() => downloadDoc(doc.id)}>
                                                    <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }} sx={{ pl: 1, pr: 2 }}>{doc.data}</TableCell>
                                                    <TableCell sx={{ px: 0 }} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '100px' }}>{doc.file}</TableCell>
                                                    <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }} sx={{ pl: 2, pr: 1 }} align='center'>
                                                        {props.isOp ? (
                                                            <IconButton sx={{ p: 0 }} onMouseDown={mouseDown} onClick={(e) => openDeleteDialog(e, doc)}>
                                                                <DeleteOutline color='error' />
                                                            </IconButton>
                                                        ) : (<Typography />)}
                                                    </TableCell>
                                                </ButtonBase>
                                            </Tooltip>
                                        ))}
                                    </TableBody>
                                </Table>
                                <Stack direction='row' alignItems='center' sx={{ pt: 1 }}>
                                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
                                    <Typography fontSize='1.1rem' sx={{ pr: 2, color: "rgba(255, 255, 255, 0.8)" }}>
                                        {`${docPage * 15 + 1} - ${docPage * 15 + docs.length} di ${docTotal}`}
                                    </Typography>
                                    <IconButton onClick={() => handleDocPage(false)} disabled={docPage == 0}><ArrowBackIos /></IconButton>
                                    <IconButton onClick={() => handleDocPage(true)} disabled={15 * (docPage + 1) >= docTotal}><ArrowForwardIos /></IconButton>
                                </Stack>
                                <Button
                                    component="label"
                                    fullWidth
                                    variant="outlined"
                                    startIcon={<UploadFile />}
                                    sx={{ my: 1 }}
                                >
                                    Invia nuovo
                                    <input type="file" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" hidden onChange={handleFileUpload} />
                                </Button>
                                {isLoadingDoc ? <Stack sx={{ my: 1 }} alignItems='center'><CircularProgress disableShrink /></Stack> : <Typography />}
                                {statusMessageDoc && (
                                    <Paper variant='outlined' sx={{
                                        p: 2, my: 1, flexGrow: 1,
                                        backgroundColor: isErrorDoc ? "#e57373" : "#81c784",
                                        borderColor: isErrorDoc ? '#d32f2f' : '#388e3c',
                                        color: 'black'
                                    }} role="alert">
                                        {statusMessageDoc}
                                    </Paper>
                                )}
                                {filename ? <Typography sx={{ m: 1 }}>{filename}</Typography> : <Typography />}
                                {filename ? <Stack sx={{ my: 1 }} spacing={1} direction='row'>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        onClick={confirmUpload}
                                        sx={{ backgroundColor: '#53432D', color: '#ECE9E6' }}
                                    >
                                        Carica
                                    </Button><Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        onClick={cancelUpload}
                                        sx={{ backgroundColor: '#53432D', color: '#ECE9E6' }}
                                    >
                                        Annulla
                                    </Button>
                                </Stack> : <Typography />}
                            </div>) : (<Typography />)}
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                sx={{ p: 2 }}
                PaperProps={{
                    style: {
                        backgroundColor: '#282520',
                    },
                }}
            >
                <DialogTitle id="dialog-title">
                    {`Eliminare il file ${fileToDelete.file}?`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-description">
                        Questa operazione è definitiva, il file non potrà essere recuperato.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Annulla</Button>
                    <Button color='error' onClick={handleConfirmDelete}>Elimina</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open4}
                onClose={() => setOpen4(false)}
                fullWidth={true}
                maxWidth='lg'
                aria-labelledby="dialog-title-1"
                sx={{ p: 2 }}
                PaperProps={{
                    style: {
                        backgroundColor: '#282520',
                    },
                }}
            >
                <DialogTitle align='center' fontSize='1.6em' id="dialog-title-1">
                    Gestione Prenotazioni
                </DialogTitle>
                <DialogContent sx={{ pb: 0 }}>
                    {calendarLoading ? (<Stack sx={{ my: 3 }} alignItems='center'><CircularProgress disableShrink /></Stack>) :
                        (<div>
                            <TableContainer sx={{ width: '100%', overflowX: 'auto' }}>
                                <Table size='small'>
                                    <TableHead>
                                        <TableRow>
                                            {calendar.map(day => (
                                                <TableCell align='center' sx={{ color: "rgba(255, 255, 255, 0.4)" }}>{day.data}</TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {indexOre.map(j => (<TableRow>
                                            {indexCalendar.map(i => (
                                                <TableCell sx={{ px: 0 }} align='center'>
                                                    <Stack direction='row' justifyContent='center' spacing={0.5}>
                                                        <Button
                                                            sx={{ backgroundColor: bgButton[calendar[i].ore[j][0].stato] }}
                                                            variant='contained'
                                                            disabled={[0, 1, 3].includes(calendar[i].ore[j][0].stato)}
                                                            onClick={() => manageOra(i, j, 0)}>{calendar[i].ore[j][0].ora}
                                                        </Button>
                                                        <Button
                                                            sx={{ backgroundColor: bgButton[calendar[i].ore[j][1].stato] }}
                                                            variant='contained'
                                                            disabled={[0, 1, 3].includes(calendar[i].ore[j][1].stato)}
                                                            onClick={() => manageOra(i, j, 1)}>{calendar[i].ore[j][1].ora}
                                                        </Button>
                                                    </Stack>
                                                </TableCell>
                                            ))}
                                        </TableRow>)
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Stack direction='row' alignItems='center' sx={{ pt: 1 }}>
                                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
                                <Typography fontSize='1.1rem' sx={{ pr: 2, color: "rgba(255, 255, 255, 0.8)" }}>
                                    {`${calendarPage * 7 + 1} - ${calendarPage * 7 + 7} di ${7 * 12}`}
                                </Typography>
                                <IconButton onClick={() => handleCalendarPage(false)} disabled={calendarPage == 0}><ArrowBackIos /></IconButton>
                                <IconButton onClick={() => handleCalendarPage(true)} disabled={calendarPage == 11}><ArrowForwardIos /></IconButton>
                            </Stack>
                        </div>)
                    }
                    {statusMessageCalendar && (
                        <Paper variant='outlined' sx={{
                            p: 2, my: 1, flexGrow: 1,
                            backgroundColor: isErrorCalendar ? "#e57373" : "#81c784 ",
                            borderColor: isErrorCalendar ? '#d32f2f' : '#388e3c',
                            color: 'black'
                        }} role="alert">
                            {statusMessageCalendar}
                        </Paper>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen4(false)}>Chiudi</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open5}
                onClose={() => setOpen5(false)}
                aria-labelledby="dialog-title-2"
                aria-describedby="dialog-description-2"
                sx={{ p: 2 }}
                PaperProps={{
                    style: {
                        backgroundColor: '#282520',
                    },
                }}
            >
                <DialogTitle id="dialog-title-2">
                    {calendarIsSetByOp ? `Giorno ${calendar[selectedSpot.i].data}, ore ${calendar[selectedSpot.i].ore[selectedSpot.j][selectedSpot.o].ora}` : ""}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-description-2">
                        Vuoi creare una prenotazione per quest'ora?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen5(false)}>Annulla</Button>
                    <Button onClick={handleAskForService}>Prenota</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open6}
                onClose={() => setOpen6(false)}
                aria-labelledby="dialog-title-3"
                aria-describedby="dialog-description-3"
                sx={{ p: 2 }}
                PaperProps={{
                    style: {
                        backgroundColor: '#282520',
                    },
                }}
            >
                <DialogTitle id="dialog-title-3">
                    {calendarIsSetByOp ? `Giorno ${calendar[selectedSpot.i].data}, ore ${calendar[selectedSpot.i].ore[selectedSpot.j][selectedSpot.o].ora}` : ""}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-description-3">
                        {calendarIsSetByOp ? `Hai prenotato per il servizio ` +
                            `${calendar[selectedSpot.i].ore[selectedSpot.j][selectedSpot.o].servizio}. ` +
                            `Vuoi eliminare questa prenotazione? Il professionista riceverà una email di avvenuta cancellazione.` : ""}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen6(false)}>Annulla</Button>
                    <Button color='error' onClick={handleAskForBookingDeletion}>Elimina</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open7}
                onClose={() => setOpen7(false)}
                aria-labelledby="dialog-title-4"
                aria-describedby="dialog-description-4"
                sx={{ p: 2 }}
                PaperProps={{
                    style: {
                        backgroundColor: '#282520',
                    },
                }}
            >
                <DialogTitle id="dialog-title-4">
                    {calendarIsSetByOp ? `Eliminare prenotazione per il ${calendar[selectedSpot.i].data} alle ${calendar[selectedSpot.i].ore[selectedSpot.j][selectedSpot.o].ora}?` : ""}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-description-4">
                        {calendarIsSetByOp ? `Sei sicuro di voler eliminare la tua prenotazione per il servizio ` +
                            `${calendar[selectedSpot.i].ore[selectedSpot.j][selectedSpot.o].servizio}?` : ""}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen7(false)}>Annulla</Button>
                    <Button color='error' onClick={handleConfirmBookingDeletion}>Elimina</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open8}
                onClose={() => setOpen8(false)}
                aria-labelledby="dialog-title-5"
                aria-describedby="dialog-description-5"
                sx={{ p: 2 }}
                PaperProps={{
                    style: {
                        backgroundColor: '#282520',
                    },
                }}
            >
                <DialogTitle id="dialog-title-5">
                    {calendarIsSetByOp ? `Prenota per il ${calendar[selectedSpot.i].data} alle ${calendar[selectedSpot.i].ore[selectedSpot.j][selectedSpot.o].ora}` : ""}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-description-5">
                        Scegli per quale servizio vuoi effettuare la prenotazione:
                    </DialogContentText>
                    <Stack style={{ display: 'flex' }} sx={{ mt: 2 }} spacing={2}>
                        <Select
                            value={selectedService.nome}
                            onChange={(event: any) => setSelectedService({ ...selectedService, nome: event.target.value, id: servizi.filter(s => s.nome == event.target.value)[0].id })}
                        >
                            {servizi.map(service => (<MenuItem value={service.nome}>{service.nome}</MenuItem>))}
                        </Select>
                        <TextField
                            required={selectedService.nome == 'Altro (Specificare)'}
                            label={selectedService.nome == 'Altro (Specificare)' ? 'Descrizione' : 'Note'}
                            value={selectedService.note}
                            onChange={(event: any) => setSelectedService({ ...selectedService, note: event.target.value })}
                            multiline
                            rows={3}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen8(false)}>Annulla</Button>
                    <Button
                        disabled={selectedService.nome == "Altro (Specificare)" && selectedService.note == ""}
                        onClick={handleBookingConfirm}
                    >Prenota</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};


export default Main;
