import axios from 'axios';
import { useEffect, useState } from 'react';
import { Box, Typography, Stack, Grid, Button, CircularProgress } from '@mui/material';
import utils from './utils';

import video from './assets/home.mp4';


import Navbar from './Navbar';
import Footer from './Footer';

const Home = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            const res = await axios.get('/api/me', { headers: utils.getAuthHeaders(), validateStatus: () => true });
            if (res.status == 200) {
                const { success, error, me } = res.data;
                if (success) {
                    window.location.href = me.op ? '/op' : '/user';
                } else {
                    console.log(error);
                }
            }
        } catch (error) {
            console.log(error)
        }
        setIsLoading(false);
    }
    return (
        <div>
            {isLoading ? (
                <Box sx={{ backgroundColor: "#1c1a17", display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <Box component="main" sx={{ flexGrow: 1, mb: 3 }}>
                        <Navbar logged={false} isOp={false} />
                        <Stack sx={{ mt: 5 }} alignItems='center'><CircularProgress disableShrink /></Stack>
                    </Box>
                </Box>) : (
                <div style={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'top' }}>
                    <div style={{ position: 'absolute', top: 0, width: '100%', zIndex: 1 }}>
                        <Navbar logged={false} isOp={false} />
                    </div>
                    <div style={{ position: 'absolute', bottom: 0, width: '100%', zIndex: 1 }}>
                        <Footer />
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            zIndex: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                        <Grid container justifyContent='center'>
                            <Grid item xs={12} md={4} sx={{ textAlign: 'center', mx: 3 }}>
                                <Typography variant='h4' sx={{ color: 'white', mb: 3 }}>Connettiti coi tuoi professionisti di fiducia in modo semplice ed efficace.</Typography>
                                <div>
                                    <Button size='large' variant='contained' href='/register' sx={{ mb: 3 }}>
                                        <Typography fontWeight='bold'>Registrati</Typography>
                                    </Button>
                                </div>
                                <Button size='large' variant='contained' href='/why'>
                                    <Typography fontWeight='bold'>Sei un Professionista?</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    <video
                        src={video}
                        autoPlay
                        muted
                        style={{ width: '100%', height: '100%', objectFit: 'cover', zIndex: -1 }}
                    />

                </div>
            )
            }
        </div >
    );
};

export default Home;
