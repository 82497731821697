import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import OpHome from './OpHome';
import Account from './Account';
import Main from './Main';
import Assistance from './Assistance';
import Login from './Login';
import Home from './Home';
import Footer from './Footer';
import Register from './Register';
import UserHome from './UserHome';
import PasswordReset from './PasswordReset';
import { Box } from '@mui/material';
import Page404 from './Page404';
import RegisterConfirm from './RegisterConfirm';
import Subscription from './Subscription';
import Admin from './Admin';
import AdminDetail from './AdminDetail';
import Terms from './Terms';
import Landing from './Landing';
import AdminLeads from './AdminLeads';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#E5C699',
    },
  },
  shape: {
    borderRadius: 18
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            backgroundColor: "#1c1a17",
          },
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '1rem'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontSize: '1rem'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none'
        }
      }
    }
  }
});


function AppContent() {
  return (
    <Box sx={{ backgroundColor: "#1c1a17", display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Box component="main" sx={{ flexGrow: 1, mb: 3 }}>
        <Routes>
          <Route path="/why" element={<Landing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/confirm" element={<RegisterConfirm />} />
          <Route path="/reset" element={<PasswordReset />} />
          <Route path="/help" element={<Assistance />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/account" element={<Account />} />
          <Route path="/op" element={<OpHome />} />
          <Route path="/op/customer" element={<Main isOp={true} />} />
          <Route path="/user" element={<UserHome />} />
          <Route path="/user/op" element={<Main isOp={false} />} />
          <Route path="/termini" element={<Terms page='terms' />} />
          <Route path="/privacy" element={<Terms page='privacy' />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/admin/user" element={<AdminDetail />} />
          <Route path="/admin/leads" element={<AdminLeads />} />
          <Route path='*' element={<Page404 />}/>
        </Routes>
      </Box>
      <Footer />
    </Box>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/*" element={<AppContent />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
