import { ChangeEvent, useState, useEffect } from 'react';
import axios from 'axios';
import utils from './utils';

import { Container, Typography, TextField, Paper, Stack, Grid, FormControlLabel, Checkbox, Button, Link, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

import Navbar from './Navbar';
import { UploadFile } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';


const emptyFormData = {
    nome: '',
    cognome: '',
    email: '',
    confirmEmail: '',
    telefono: '',
    codiceFiscale: '',
    password: '',
    confirmPassword: '',
    partitaIva: '',
    nomeAzienda: '',
    indirizzo: '',
    citta: '',
    cap: '',
    provincia: '',
};

const Register = () => {

    // info dialog
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [formData, setFormData] = useState(emptyFormData);

    const [statusMessage, setStatusMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const [filename, setFilename] = useState("");
    const [imageFile, setImageFile] = useState<File | null>(null);

    useEffect(() => {
        if (isOp()) {
            utils.sendAnalyticsHearthbeat('register_page');
            const interval = setInterval(() => {
                utils.sendAnalyticsHearthbeat('register_page');
            }, 1000);
            return () => clearInterval(interval);
        }
    }, []);

    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];
        if (file) {
            setImageFile(file);
            setFilename(file.name);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen2 = () => {
        setOpen2(true);
        if (isOp()) {
            utils.sendAnalyticsEvent('open_id_card', null);
        }
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    const handleChange = (e: any) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const strongPassword = (str: string) => {
        return !!str && /(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})/.test(str);
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (formData.email !== formData.confirmEmail) {
            setIsError(true);
            setStatusMessage('Le email non coincidono.');
            return;
        }

        if (!strongPassword(formData.password)) {
            setIsError(true);
            setStatusMessage('La password deve essere lunga almeno 8 caretteri e contenere almeno una lettera e un numero.');
            return;
        }

        if (formData.password !== formData.confirmPassword) {
            setIsError(true);
            setStatusMessage('Le password non coincidono.');
            return;
        }
        const json = { ...formData, imageName: "", op: false };
        const payload = new FormData();
        if (isOp()) {
            if (!imageFile) {
                setIsError(true);
                setStatusMessage('Nessun immagine caricata.');
                return;
            }
            json.imageName = filename;
            json.op = true;
            payload.append('image', imageFile as Blob);
        }
        payload.append('data', JSON.stringify(json));

        try {
            setLoading(true);
            const res = await axios.post('/api/register', payload, { headers: { "Content-Type": 'multipart/form-data' } });
            const { success, error } = res.data;
            setLoading(false);
            if (success) {
                setIsError(false);
                const secondPart = " Se non l'hai ricevuta, controlla lo spam e la correttezza della mail inserita. Nel caso avessi inserito una email errata, puoi ripetere la registrazione con la email corretta.";
                if (isOp()) {
                    setStatusMessage(`Ti è stata inviata a ${formData.email} una email di avvenuta registrazione.` + secondPart);
                    utils.sendAnalyticsEvent('registered', null);
                } else {
                    setStatusMessage(`Per favore, clicca sul link che ti è stato inviato a ${formData.email} per confermare la tua email.` + secondPart);
                }
            } else {
                setIsError(true);
                setStatusMessage(error);
                if (isOp()) {
                    utils.sendAnalyticsEvent('registered', error);
                }
                return;
            }
        } catch (error: any) {
            setLoading(false);
            setIsError(true);
            if (error.response && error.response.status == 413) {
                setStatusMessage("L'immagine è troppo grande. Il limite massimo è di 10 MB.");
            } else {
                setStatusMessage("Si è verificato un errore imprevisto sul nostro server.");
            }
            if (isOp()) {
                utils.sendAnalyticsEvent('registered', error);
            }
            return;
        }
        setFormData(emptyFormData);
    };

    const isOp = () => window.location.search.includes('op=true');

    return (
        <div>
            <Navbar logged={false} isOp={false} />
            <Container>
                <Typography variant="h3" align="center" color="#ECE9E6" gutterBottom sx={{ p: 2, mb: 1 }}>
                    Registrati come {isOp() ? 'Professionista' : 'Cliente'}
                </Typography>
                <Typography align="center" color="#ECE9E6" gutterBottom sx={{ pb: 2 }}>
                    <span>{'Stai creando un account di tipo '}</span><span style={{ fontWeight: 'bold' }}>{isOp() ? '"Professionista". ' : '"Cliente". '}</span>
                    <Link href={`/register${isOp() ? '' : '?op=true'}`} variant="body2">
                        {isOp() ? 'Registrati come "Cliente"' : 'Registrati come "Professionista"'}
                    </Link>
                </Typography>
                <Paper sx={{ px: 4, pt: 4, pb: 3, backgroundColor: '#282520' }}>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Nome"
                                    name='nome'
                                    fullWidth
                                    size='small'
                                    value={formData.nome}
                                    onChange={handleChange}
                                    required
                                    onInvalid={F => (F.target as HTMLInputElement).setCustomValidity('Questo campo è obbligatorio')}
                                    onInput={F => (F.target as HTMLInputElement).setCustomValidity('')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Cognome"
                                    name='cognome'
                                    fullWidth
                                    size='small'
                                    value={formData.cognome}
                                    onChange={handleChange}
                                    required
                                    onInvalid={F => (F.target as HTMLInputElement).setCustomValidity('Questo campo è obbligatorio')}
                                    onInput={F => (F.target as HTMLInputElement).setCustomValidity('')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Email"
                                    name='email'
                                    fullWidth
                                    size='small'
                                    required
                                    value={formData.email}
                                    onChange={handleChange}
                                    onInvalid={F => (F.target as HTMLInputElement).setCustomValidity('Questo campo è obbligatorio')}
                                    onInput={F => (F.target as HTMLInputElement).setCustomValidity('')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Conferma Email"
                                    name='confirmEmail'
                                    fullWidth
                                    size='small'
                                    required
                                    value={formData.confirmEmail}
                                    onChange={handleChange}
                                    onInvalid={F => (F.target as HTMLInputElement).setCustomValidity('Questo campo è obbligatorio')}
                                    onInput={F => (F.target as HTMLInputElement).setCustomValidity('')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Password"
                                    name='password'
                                    fullWidth
                                    size='small'
                                    required
                                    type="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    onInvalid={F => (F.target as HTMLInputElement).setCustomValidity('Questo campo è obbligatorio')}
                                    onInput={F => (F.target as HTMLInputElement).setCustomValidity('')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Conferma Password"
                                    name='confirmPassword'
                                    fullWidth
                                    size='small'
                                    required
                                    type="password"
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    onInvalid={F => (F.target as HTMLInputElement).setCustomValidity('Questo campo è obbligatorio')}
                                    onInput={F => (F.target as HTMLInputElement).setCustomValidity('')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField label="Telefono" fullWidth required type="tel"
                                    size='small'
                                    name='telefono'
                                    value={formData.telefono}
                                    onChange={handleChange}
                                    onInvalid={F => (F.target as HTMLInputElement).setCustomValidity('Questo campo è obbligatorio')}
                                    onInput={F => (F.target as HTMLInputElement).setCustomValidity('')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField label="Codice Fiscale" fullWidth required
                                    size='small'
                                    name='codiceFiscale'
                                    value={formData.codiceFiscale}
                                    onChange={handleChange}
                                    onInvalid={F => (F.target as HTMLInputElement).setCustomValidity('Questo campo è obbligatorio')}
                                    onInput={F => (F.target as HTMLInputElement).setCustomValidity('')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField label="Partita IVA"
                                    fullWidth
                                    name='partitaIva'
                                    value={formData.partitaIva}
                                    onChange={handleChange}
                                    size='small' />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField label="Nome Azienda/Denom. Attività"
                                    required={isOp()}
                                    fullWidth
                                    name='nomeAzienda'
                                    value={formData.nomeAzienda}
                                    onChange={handleChange}
                                    size='small' />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label={isOp() ? "Indirizzo Azienda/Attività" : "Indirizzo"} fullWidth
                                    required={isOp()}
                                    name='indirizzo'
                                    value={formData.indirizzo}
                                    onChange={handleChange}
                                    size='small'
                                    onInvalid={F => (F.target as HTMLInputElement).setCustomValidity('Questo campo è obbligatorio')}
                                    onInput={F => (F.target as HTMLInputElement).setCustomValidity('')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField label="Città" fullWidth
                                    required={isOp()}
                                    name='citta'
                                    size='small'
                                    value={formData.citta}
                                    onChange={handleChange}
                                    onInvalid={F => (F.target as HTMLInputElement).setCustomValidity('Questo campo è obbligatorio')}
                                    onInput={F => (F.target as HTMLInputElement).setCustomValidity('')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField label="CAP" fullWidth
                                    required={isOp()}
                                    name='cap'
                                    size='small'
                                    value={formData.cap}
                                    onChange={handleChange}
                                    onInvalid={F => (F.target as HTMLInputElement).setCustomValidity('Questo campo è obbligatorio')}
                                    onInput={F => (F.target as HTMLInputElement).setCustomValidity('')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField label="Provincia" fullWidth
                                    required={isOp()}
                                    name='provincia'
                                    size='small'
                                    value={formData.provincia}
                                    onChange={handleChange}
                                    onInvalid={F => (F.target as HTMLInputElement).setCustomValidity('Questo campo è obbligatorio')}
                                    onInput={F => (F.target as HTMLInputElement).setCustomValidity('')}
                                />
                            </Grid>

                            {isOp() ? (<Stack direction='row' sx={{ pl: 2, pt: 2 }}><Button
                                component="label"
                                variant="outlined"
                                startIcon={<UploadFile />}
                                sx={{ marginRight: "1rem" }}
                            >
                                Carica la tua Carta d'Identità*
                                <input type="file" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" hidden onChange={handleFileUpload} />
                            </Button>{filename ?
                                (<Typography sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>{filename}</Typography>) :
                                (<Link href='#' onClick={handleClickOpen2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }} variant="body2">
                                    *A cosa serve?
                                </Link>)}</Stack>) : (<Typography />)}

                            <Grid container sx={{ mt: 1, pl: 3, pr: 2 }} alignContent='center' alignItems='center' justifyContent='center'>
                                <Grid item xs>
                                    <FormControlLabel
                                        control={<Checkbox required
                                            value="remember"
                                            onInvalid={F => (F.target as HTMLInputElement).setCustomValidity('Devi accettare i termini e condizioni per registrarti.')}
                                            onInput={F => (F.target as HTMLInputElement).setCustomValidity('')}
                                            color="primary"
                                        />}
                                        label={
                                            <div>
                                                <span>Ho letto e accetto i </span>
                                                <Link href={'/termini'}>Termini di Servizio</Link>
                                                <span> e la </span>
                                                <Link href={'/privacy'}>Privacy Policy</Link>
                                            </div>
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    {isOp() ? (<Link href='#' onClick={handleClickOpen} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }} variant="body2">
                                        Come funziona l'abbonamento?
                                    </Link>) : (<Typography />)}

                                </Grid>
                            </Grid>
                            {statusMessage && (
                                <Paper variant='outlined' sx={{
                                    p: 2, ml: 2, my: 1, width: '100%',
                                    backgroundColor: isError ? "#e57373" : "#81c784",
                                    borderColor: isError ? '#d32f2f' : '#388e3c',
                                    color: 'black'
                                }} role="alert">
                                    {statusMessage}
                                </Paper>
                            )}
                            <Grid container>
                                <Grid item xs={12} sx={{ pl: 2 }}>
                                    <LoadingButton
                                        loading={isLoading}
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ backgroundColor: '#53432D', color: '#ECE9E6', my: 2, fontSize: '110%' }}
                                    >
                                        Completa Registrazione
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>

                    <Typography align='center'>
                        <span>Hai già un account? </span>
                        <Link href="/login" variant="body2">
                            Accedi
                        </Link>
                    </Typography>
                </Paper>
            </Container >
            <Dialog
                open={open2}
                onClose={handleClose2}
                aria-labelledby="id-dialog-title"
                aria-describedby="id-dialog-description"
                sx={{ p: 2 }}
                PaperProps={{
                    style: {
                        backgroundColor: '#282520',
                    },
                }}
            >
                <DialogTitle id="id-dialog-title">
                    {"A cosa serve la tua carta d'identità"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="id-dialog-description">
                        MyExperts richiede che durante l'iscrizione tu fornisca la tua carta d'identità.
                        Questo per evitare che qualcuno si possa iscrivere spacciandosi per te e possa truffare
                        i tuoi clienti chiedendo denaro o documenti sensibili. La tua carta d'identità
                        rimane conservata al sicuro nei file server di Aruba insieme a tutti i documenti
                        tuoi e dei tuoi clienti.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose2}>
                        Chiudi
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="info-dialog-title"
                aria-describedby="info-dialog-description"
                sx={{ p: 2 }}
                PaperProps={{
                    style: {
                        backgroundColor: '#282520',
                    },
                }}
            >
                <DialogTitle id="info-dialog-title">
                    {"Abbonarsi a MyExperts"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="info-dialog-description">
                        MyExperts offre ai professionisti un mese di prova del servizio completamente gratuita.
                        Non è richiesto alcun metodo di pagamento. Dopo il primo mese, per continuare ad utilizzare
                        il servizio, viene richiesto il pagamento di un canone annuale.
                        Nel canone (e la prova gratuita) sono compresi 10GB di spazio di archiviazione per
                        i documenti che scambi con i tuoi clienti. 10GB possono contenere mediamente un milione di pagine
                        PDF di testo o 10.000 pagine con immagini ad alta risoluzione. I documenti che non ti servono o
                        che preferisci non tenere archiviati su MyExperts possono essere ovviamente eliminati per guadagnare
                        spazio. Nel caso 10GB non ti bastassero, puoi aggiungerne quanti ne desideri al costo di 15 euro all'anno
                        per ogni 10GB ulteriori.
                        Tutti i documenti archiviati su MyExperts vengono fisicamente
                        salvati sui file server Aruba situati nel territorio italiano e conformi a stringenti regole di
                        sicurezza.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Chiudi
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default Register;
