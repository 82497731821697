import axios from 'axios';
import { useState, useEffect } from 'react';
import { Container, Typography, TextField, Paper, Stack, Grid, FormControlLabel, Checkbox, Button, Link, CircularProgress } from '@mui/material';

import Navbar from './Navbar';
import Page404 from './Page404';


const RegisterConfirm = () => {

    const [statusMessage, setStatusMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        loadData();
    }, []);

    const urlParams = new URLSearchParams(window.location.search);
    const id = Number(urlParams.get('id'));
    const token = urlParams.get('token');
    if (!id || !token) {
        return (<div>
            <Navbar logged={false} isOp={false} />
            <Page404 />
        </div>);
    }

    const loadData = async () => {
        try {
            const res = await axios.post('/api/confirm?', { id, token });
            const { success, error } = res.data;
            if (success) {
                setStatusMessage("Grazie per aver confermato la tua email.");
            } else {
                setIsError(true);
                setStatusMessage(error);
            }
        } catch (error) {
            setIsError(true);
            console.log(error)
            setStatusMessage("Si è verificato un errore imprevisto sul nostro server.");
        }
        setIsLoading(false);
    }

    return (
        <div>
            <Navbar logged={false} isOp={false}/>
            <Container>
                <Typography variant="h3" align="center" color="#ECE9E6" gutterBottom sx={{ p: 2 }}>
                    Conferma Email
                </Typography>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item md={6}>
                        <Paper sx={{ p: 3, backgroundColor: '#282520' }}>
                            {isLoading ? (<CircularProgress disableShrink />) : (
                                <div>{statusMessage && (
                                    <Paper variant='outlined' sx={{
                                        p: 2, my: 1, flexGrow: 1,
                                        backgroundColor: isError ? "#e57373" : "#81c784 ",
                                        borderColor: isError ? '#d32f2f' : '#388e3c',
                                        color: 'black'
                                    }} role="alert">
                                        {statusMessage}
                                    </Paper>
                                )}
                                    {isError ? (<Typography />) : (<Typography align='center'>
                                        <span>Ora puoi effettuare l'</span>
                                        <Link href="/login" variant="body2">
                                            Accesso
                                        </Link>
                                    </Typography>)}</div>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Container >
        </div >
    );
};

export default RegisterConfirm;
