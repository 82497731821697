import axios from 'axios';
import utils from './utils';
import { useState, useEffect } from 'react';
import { Container, TableContainer, Typography, TextField, ButtonBase, Table, TableHead, TableRow, TableCell, TableBody, InputAdornment, Paper, SxProps, IconButton, Stack,  CircularProgress } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos, CheckCircleOutline, HighlightOffOutlined, Search } from '@mui/icons-material';

type User = {
    id: number;
    nome: string;
    cognome: string;
    email: string;
    telefono: string;
    op: boolean;
    attivo: boolean;
}

const dataRowSX: SxProps = {
    display: "table-row",
    ":hover": {
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        cursor: "pointer",
    },
};

const Admin = () => {

    const [users, setUsers] = useState([] as User[]);
    const [userSearch, setUserSearch] = useState("");
    const [userTotal, setUserTotal] = useState(0);
    const [userPage, setUserPage] = useState(0);
    const [userLoading, setUserLoading] = useState(true);
    const [statusMessageUsers, setStatusMessageUsers] = useState('');

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        loadData();
        loadUsers(0, "");
    }, []);

    const loadData = async () => {
        try {
            const res = await axios.get('/api/me', { headers: utils.getAuthHeaders(), validateStatus: () => true });
            if (res.status == 200) {
                const { success, error, me } = res.data;
                if (success && me.email == 'michelerosellib@gmail.com') {
                    setIsLoading(false);
                    return; // stay on the page
                } else if (error) {
                    console.log(error);
                }
            }
        } catch (error) {
            console.log(error)
        }
        setIsLoading(false);
        if (process.env.REACT_APP_ENV != 'test') {
            window.location.href = '/login';
        }
    }

    const loadUsers = async (p: number, q: string) => {
        try {
            const res = await axios.get(`/api/adminclienti?page=${p}${q ? '&q=' + q : ""}`, { headers: utils.getAuthHeaders() });
            const { success, error } = res.data;
            if (success) {
                setUsers(res.data.users);
                setUserTotal(res.data.total);
            } else {
                setStatusMessageUsers(error);
            }
        } catch (error) {
            setStatusMessageUsers("Si è verificato un errore imprevisto sul nostro server.");
            console.log(error)
        }
        setUserLoading(false);
    }

    const handleUserSearch = (e: any) => {
        setUserPage(0);
        setUserSearch(e.target.value);
        setUserLoading(true);
        loadUsers(0, e.target.value);
    }

    const handleUserPage = (forward: boolean) => {
        if (userPage == 0 && !forward) {
            return;
        }
        if (5 * (userPage + 1) >= userTotal && forward) {
            return;
        }
        const newPage = userPage + (forward ? 1 : -1)
        setUserPage(newPage);
        setUserLoading(true);
        loadUsers(newPage, userSearch);
    }

    const openCustomer = (id: number) => {
        return function () {
            window.location.href = `/admin/user?id=${id}`;
        }
    }

    return (
        <div>
            {isLoading ? (<Stack sx={{ mt: 5 }} alignItems='center'><CircularProgress disableShrink /></Stack>) : (
                <Container>
                    <Paper sx={{ px: 3, pt: 3, pb: 2, mt: 5, backgroundColor: '#282520' }}>
                        <Typography variant="h4" color="#ECE9E6" gutterBottom sx={{ pl: 1, mb: 2 }}>
                            Lista Utenti
                        </Typography>
                        <div style={{ display: 'flex' }}>
                            <TextField
                                id="outlined-size-small"
                                size="small"
                                placeholder='Cerca...'
                                onChange={handleUserSearch}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{ flexGrow: 1 }}
                            />
                        </div>
                        {userLoading ? (<Stack sx={{ my: 3 }} alignItems='center'><CircularProgress disableShrink /></Stack>) :
                            (<div>
                                <TableContainer sx={{ width: '100%', overflowX: 'auto' }}>
                                    <Table size='small' sx={{ mt: 3 }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: "rgba(255, 255, 255, 0.4)" }}>ID</TableCell>
                                                <TableCell sx={{ color: "rgba(255, 255, 255, 0.4)" }}>COGNOME</TableCell>
                                                <TableCell sx={{ color: "rgba(255, 255, 255, 0.4)" }}>NOME</TableCell>
                                                <TableCell sx={{ color: "rgba(255, 255, 255, 0.4)" }}>EMAIL</TableCell>
                                                <TableCell sx={{ color: "rgba(255, 255, 255, 0.4)" }}>TELEFONO</TableCell>
                                                <TableCell align='center' sx={{ color: "rgba(255, 255, 255, 0.4)" }}>OP</TableCell>
                                                <TableCell align='center' sx={{ color: "rgba(255, 255, 255, 0.4)" }}>ATTIVO</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {users.map(user => (<ButtonBase onClick={openCustomer(user.id)} key={user.id} component={TableRow} sx={dataRowSX}>
                                                <TableCell>{user.id}</TableCell>
                                                <TableCell>{user.cognome}</TableCell>
                                                <TableCell>{user.nome}</TableCell>
                                                <TableCell>{user.email}</TableCell>
                                                <TableCell>{user.telefono}</TableCell>
                                                <TableCell align='center'>{user.op ? (<CheckCircleOutline color='success' />) : (<HighlightOffOutlined color='error' />)}</TableCell>
                                                <TableCell align='center'>{user.attivo ? (<CheckCircleOutline color='success' />) : (<HighlightOffOutlined color='error' />)}</TableCell>
                                            </ButtonBase>)
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Stack direction='row' alignItems='center' sx={{ pt: 1 }}>
                                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
                                    <Typography fontSize='1.1rem' sx={{ pr: 2, color: "rgba(255, 255, 255, 0.8)" }}>
                                        {`${userPage * 15 + 1} - ${userPage * 15 + users.length} di ${userTotal}`}
                                    </Typography>
                                    <IconButton onClick={() => handleUserPage(false)} disabled={userPage == 0}><ArrowBackIos /></IconButton>
                                    <IconButton onClick={() => handleUserPage(true)} disabled={15 * (userPage + 1) >= userTotal}><ArrowForwardIos /></IconButton>
                                </Stack>
                            </div>)}
                        {statusMessageUsers && (
                            <Paper variant='outlined' sx={{
                                p: 2, my: 1, flexGrow: 1,
                                backgroundColor: "#e57373",
                                borderColor: '#d32f2f',
                                color: 'black'
                            }} role="alert">
                                {statusMessageUsers}
                            </Paper>
                        )}
                    </Paper>
                </Container >)}
        </div >
    );
};

export default Admin;
