import axios from 'axios';
import { Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Link, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import Navbar from './Navbar';
import { ArrowBackIos, ArrowForwardIos, Check, CheckBox } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import utils from './utils';

const indexCalendar = [0, 1, 2, 3, 4, 5, 6];

const Landing = () => {

    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    const [calendar, setCalendar] = useState([] as any[]);
    const [indexOre, setIndexOre] = useState([] as number[]);
    const [calendarPage, setCalendarPage] = useState(0);
    const [calendarLoading, setCalendarLoading] = useState(true);
    const [statusMessageCalendar, setStatusMessageCalendar] = useState('');
    const [isErrorCalendar, setIsErrorCalendar] = useState(false);
    const [servizi, setServizi] = useState([] as any[]);

    // bookings
    const [open5, setOpen5] = useState(false);
    const [open8, setOpen8] = useState(false);
    const [selectedSpot, setSelectedSpot] = useState({ i: 0, j: 0, o: 0 });
    const [userInfo, setUserInfo] = useState({ nome: "", cognome: "", email: "" });
    const [selectedService, setSelectedService] = useState({ id: 0, nome: "", note: "" });

    useEffect(() => {
        loadCalendar(0, null);
        utils.sendAnalyticsHearthbeat('landing_page');
        const interval = setInterval(() => {
            utils.sendAnalyticsHearthbeat('landing_page');
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const loadCalendar = async (p: number, successMessage: string | null) => {
        try {
            const res = await axios.get(`/api/calendariomio?page=${p}`);
            const { success, error } = res.data;
            if (success) {
                if (successMessage) {
                    setIsErrorCalendar(false);
                    setStatusMessageCalendar(successMessage)
                }
                setCalendar(res.data.calendario);
                setIndexOre([...Array(res.data.calendario[0].ore.length).keys()]);
                const reordered: any[] = [];
                let altroService;
                for (const s of res.data.servizi) {
                    if (s.nome == 'Altro (Specificare)') {
                        altroService = s;
                    } else {
                        reordered.push(s);
                    }
                }
                reordered.push(altroService);
                setServizi(reordered);
            } else {
                setIsErrorCalendar(true);
                setStatusMessageCalendar(error);
            }
        } catch (error) {
            setIsErrorCalendar(true);
            setStatusMessageCalendar("Si è verificato un errore imprevisto sul nostro server.");
            console.log(error)
        }
        setCalendarLoading(false);
    }

    const handleCalendarPage = (forward: boolean) => {
        if (calendarPage == 0 && !forward) {
            return;
        }
        if (calendarPage == 11 && forward) {
            return;
        }
        const newPage = calendarPage + (forward ? 1 : -1)
        setCalendarPage(newPage);
        setCalendarLoading(true);
        loadCalendar(newPage, null);
    }

    const openAgenda = () => {
        setOpen2(true);
        utils.sendAnalyticsEvent('opened_agenda', null);
    }

    const manageOra = (i: number, j: number, o: number) => {
        setSelectedSpot({ i, j, o });
        if (calendar[i].ore[j][o].stato == 2) {
            setOpen5(true);
        } else {
            setSelectedSpot({ i: 0, j: 0, o: 0 });
        }
        utils.sendAnalyticsEvent('selected_ora', null);
    }

    const handleAskForService = () => {
        // initialize default
        setSelectedService({ id: servizi.length ? servizi[0].id : 0, nome: servizi.length ? servizi[0].nome : "", note: "" });
        setOpen5(false);
        setOpen8(true);
        utils.sendAnalyticsEvent('confirmed_ora', null);
    }

    const handleBookingConfirm = async () => {
        setOpen8(false);
        try {
            setStatusMessageCalendar("");
            setCalendarLoading(true);
            const serviceId = selectedService.id;
            const note = selectedService.note;
            const giorno = calendar[selectedSpot.i].isoDate;
            const ora = `${calendar[selectedSpot.i].ore[selectedSpot.j][selectedSpot.o].ora.substring(0, 2)}${calendar[selectedSpot.i].ore[selectedSpot.j][selectedSpot.o].ora.substring(3, 5)}`;
            const res = await axios.post('/api/createprenotazionemia', { ...userInfo, ora, giorno, serviceId, note });
            const { success, error } = res.data;
            if (success) {
                loadCalendar(calendarPage, "Prenotazione creata con successo. Riceverai una email coi dettagli.");
                utils.sendAnalyticsEvent('booking', null);
            } else {
                setCalendarLoading(false);
                setIsErrorCalendar(true);
                setStatusMessageCalendar(error);
                utils.sendAnalyticsEvent('booking', error);
            }
        } catch (error: any) {
            setCalendarLoading(false);
            setIsErrorCalendar(true);
            setStatusMessageCalendar("Si è verificato un errore imprevisto sul nostro server.");
            console.log(error)
            utils.sendAnalyticsEvent('booking', error);
        }
    }

    const openPrice = () => {
        setOpen(true);
        utils.sendAnalyticsEvent('see_price', null);
    }

    return (<div>
        <Navbar logged={false} isOp={false} />
        <Container>
            <Typography variant="h4" align='center' color="#ECE9E6" gutterBottom sx={{ m: 3 }}>
                Perché scegliere MyExperts?
            </Typography>
            <Typography variant="h4" align='center' sx={{ fontSize: { xs: '28px', md: '40px' } }} color="#E5C699" gutterBottom>
                MODERNA, SEMPLICE, INTUITIVA <Check sx={{ color: '#81c784', fontSize: 30 }} />
            </Typography>
            <Grid container spacing={3} mb={4}>
                <Grid item xs={12} md={7}>
                    <Typography color="#ECE9E6" fontSize={20} gutterBottom mb={2} px={2}>
                        Dimentica interfacce complicate e frustranti!
                        MyExperts ti garantisce un'esperienza <span style={{ fontWeight: 'bold' }}>moderna e user-friendly</span> fin da subito.
                    </Typography>
                    <Typography color="#ECE9E6" fontSize={20} gutterBottom px={2}>
                        Un gestionale che renderà il rapporto coi tuoi clienti semplice e veloce come mai prima d'ora.
                        Niente più spreco di tempo, solo <span style={{ fontWeight: 'bold' }}>efficienza e facilità di utilizzo</span>.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Box sx={{ background: 'url(img1.png)', backgroundSize: 'cover', minHeight: { xs: '25vh', md: '12vh' }, height: '100%', border: 2, borderColor: '#53432D', borderRadius: '10px' }} />
                </Grid>
            </Grid>
            <Typography variant="h4" align='center' sx={{ fontSize: { xs: '28px', md: '40px' } }} color="#E5C699" gutterBottom>
                TOTALMENTE AUTOMATIZZATA <Check sx={{ color: '#81c784', fontSize: 30 }} />
            </Typography>
            <Grid container spacing={3} mb={4}>
                <Grid item xs={12} md={7}>
                    <Typography color="#ECE9E6" fontSize={20} gutterBottom mb={2} px={2}>
                        Risparmia ore di chiamate e messaggi per organizzare gli appuntamenti. Dopo un rapido setup,
                        potrai gestire <span style={{ fontWeight: 'bold' }}>prenotazioni e comunicazioni</span> in un lampo dedicando così maggior tempo allo svolgimento dei tuoi servizi.
                    </Typography>
                    <Typography color="#ECE9E6" fontSize={20} gutterBottom px={2}>
                        Grazie a <span style={{ fontWeight: 'bold' }}>promemoria ed email informative automatiche</span>, non dovrai più pensare alle operazioni ripetitive di gestione.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Box sx={{ background: 'url(img2.png)', backgroundSize: 'cover', minHeight: { xs: '25vh', md: '12vh' }, height: '100%', border: 2, borderColor: '#53432D', borderRadius: '10px' }} />
                </Grid>
            </Grid>
            <Typography variant="h4" align='center' sx={{ fontSize: { xs: '28px', md: '40px' } }} color="#E5C699" gutterBottom>
                FLESSIBILE E PERSONALIZZABILE <Check sx={{ color: '#81c784', fontSize: 30 }} />
            </Typography>
            <Grid container spacing={3} mb={4}>
                <Grid item xs={12} md={7}>
                    <Typography color="#ECE9E6" fontSize={20} gutterBottom mb={2} px={2}>
                        Personalizza <span style={{ fontWeight: 'bold' }}>il tuo calendario, i tuoi servizi e le tue email automatiche</span>. MyExperts è pensata per darti tutta la flessibilità di cui hai bisogno.
                    </Typography>
                    <Typography color="#ECE9E6" fontSize={20} gutterBottom px={2}>
                        Inoltre, se pensi di aver bisogno di <span style={{ fontWeight: 'bold' }}>nuove funzionalità</span> che potrebbero migliorare la tua esperienza di utilizzo e quella degli altri utenti,
                        sarò lieto di ascoltare le tue proposte ed eventualmente aggiungerle all'applicazione senza nessun costo aggiuntivo da parte tua!
                    </Typography>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Box sx={{ background: 'url(img3.png)', backgroundSize: 'cover', minHeight: { xs: '25vh', md: '12vh' }, height: '100%', border: 2, borderColor: '#53432D', borderRadius: '10px' }} />
                </Grid>
            </Grid>
            <Typography variant="h4" align='center' sx={{ fontSize: { xs: '28px', md: '40px' } }} color="#E5C699" gutterBottom>
                APPROFONDIAMOLA INSIEME
            </Typography>
            <Typography color="#ECE9E6" fontSize={20} gutterBottom mb={3} px={2}>
                Prenota subito un breve <span style={{ fontWeight: 'bold' }}>appuntamento gratuito</span> per scoprire se MyExperts fa il caso tuo!
            </Typography>
            <Grid container justifyContent='center' mb={5} px={2}>
                <Grid item xs={12} md={5}>
                    <Button fullWidth size='large' variant='contained' sx={{ backgroundColor: "#81c784" }} href='#' onClick={openAgenda}>
                        <Typography align='center' fontWeight='bold' my={1}>Verifica Disponibilità</Typography>
                    </Button>
                </Grid>
            </Grid>
            <Typography variant="h4" align='center' sx={{ fontSize: { xs: '28px', md: '40px' } }} color="#E5C699" gutterBottom>
                O PROVALA SUBITO!
            </Typography>
            <Typography color="#ECE9E6" fontSize={20} gutterBottom mb={2} px={2}>
                Registrati in <span style={{ fontWeight: 'bold' }}>pochi minuti</span> confermando la tua identità e inizia subito a provare MyExperts!
                Dopo un rapidissimo tutorial, <span style={{ fontWeight: 'bold' }}>in meno di un'ora</span> avrai completato tutti i preparativi e sarai pronto ad utilizzarla con i tuoi clienti.
            </Typography>
            <Typography color="#ECE9E6" fontSize={20} gutterBottom mb={3} px={2}>
                Se fra un mese sei soddisfatto dell'applicazione e vuoi continuare ad utilizzarla, solo allora ti chiederò il pagamento {"del "}
                <Link href="#" onClick={openPrice} style={{ fontSize: 20 }}>
                    canone annuale
                </Link>{" con regolare fattura."}
            </Typography>
            <Grid container justifyContent='center' px={2}>
                <Grid item xs={12} md={5}>
                    <Button fullWidth size='large' variant='contained' href='/register?op=true'>
                        <Typography my={1} align='center' fontWeight='bold'>Registrati e prova subito myexperts!</Typography>
                    </Button>
                </Grid>
            </Grid>
        </Container>
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="id-dialog-title"
            aria-describedby="id-dialog-description"
            sx={{ p: 2 }}
            PaperProps={{
                style: {
                    backgroundColor: '#282520',
                },
            }}
        >
            <DialogTitle id="id-dialog-title">
                Abbonarsi a MyExperts
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="id-dialog-description">
                    Se dopo il mese di prova gratuita vuoi continuare ad utilizzare MyExperts,
                    ti costerà solo <span style={{ fontWeight: 'bold' }}>23.90€</span> + IVA al mese per 12 mesi.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>
                    Chiudi
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog
            open={open2}
            onClose={() => setOpen2(false)}
            fullWidth={true}
            maxWidth='lg'
            aria-labelledby="dialog-title-1"
            sx={{ p: 2 }}
            PaperProps={{
                style: {
                    backgroundColor: '#282520',
                },
            }}
        >
            <DialogTitle align='center' fontSize='1.6em' id="dialog-title-1">
                Prenota un appuntamento online
            </DialogTitle>
            <DialogContent sx={{ pb: 0 }}>
                <DialogContentText mb={1} align='center'>
                    Esattamente come i tuoi clienti prenoteranno gli appuntamenti con te su MyExperts. Scegli l'orario,
                    il servizio e ricevi subito un'email di conferma!
                </DialogContentText>
                {calendarLoading ? (<Stack sx={{ my: 3 }} alignItems='center'><CircularProgress disableShrink /></Stack>) :
                    (<div>
                        <TableContainer sx={{ width: '100%', overflowX: 'auto' }}>
                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        {calendar.map(day => (
                                            <TableCell align='center' sx={{ color: "rgba(255, 255, 255, 0.4)" }}>{day.data}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {indexOre.map(j => (<TableRow>
                                        {indexCalendar.map(i => (
                                            <TableCell sx={{ px: 0 }} align='center'>
                                                <Stack direction='row' justifyContent='center' spacing={0.5}>
                                                    <Button
                                                        sx={{ backgroundColor: calendar[i].ore[j][0].stato == 2 ? '#81c784' : undefined }}
                                                        variant='contained'
                                                        disabled={calendar[i].ore[j][0].stato != 2}
                                                        onClick={() => manageOra(i, j, 0)}>{calendar[i].ore[j][0].ora}
                                                    </Button>
                                                    <Button
                                                        sx={{ backgroundColor: calendar[i].ore[j][1].stato == 2 ? '#81c784' : undefined }}
                                                        variant='contained'
                                                        disabled={calendar[i].ore[j][1].stato != 2}
                                                        onClick={() => manageOra(i, j, 1)}>{calendar[i].ore[j][1].ora}
                                                    </Button>
                                                </Stack>
                                            </TableCell>
                                        ))}
                                    </TableRow>)
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Stack direction='row' alignItems='center' sx={{ pt: 1 }}>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
                            <Typography fontSize='1.1rem' sx={{ pr: 2, color: "rgba(255, 255, 255, 0.8)" }}>
                                {`${calendarPage * 7 + 1} - ${calendarPage * 7 + 7} di ${7 * 12}`}
                            </Typography>
                            <IconButton onClick={() => handleCalendarPage(false)} disabled={calendarPage == 0}><ArrowBackIos /></IconButton>
                            <IconButton onClick={() => handleCalendarPage(true)} disabled={calendarPage == 11}><ArrowForwardIos /></IconButton>
                        </Stack>
                    </div>)
                }
                {statusMessageCalendar && (
                    <Paper variant='outlined' sx={{
                        p: 2, my: 1, flexGrow: 1,
                        backgroundColor: isErrorCalendar ? "#e57373" : "#81c784 ",
                        borderColor: isErrorCalendar ? '#d32f2f' : '#388e3c',
                        color: 'black'
                    }} role="alert">
                        {statusMessageCalendar}
                    </Paper>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen2(false)}>Chiudi</Button>
            </DialogActions>
        </Dialog>
        <Dialog
            open={open5}
            onClose={() => setOpen5(false)}
            aria-labelledby="dialog-title-2"
            aria-describedby="dialog-description-2"
            sx={{ p: 2 }}
            PaperProps={{
                style: {
                    backgroundColor: '#282520',
                },
            }}
        >
            <DialogTitle id="dialog-title-2">
                {calendar.length ? `Giorno ${calendar[selectedSpot.i].data}, ore ${calendar[selectedSpot.i].ore[selectedSpot.j][selectedSpot.o].ora}` : ""}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="dialog-description-2">
                    Vuoi fissare un appuntamento per quest'ora?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen5(false)}>Annulla</Button>
                <Button onClick={handleAskForService}>Conferma</Button>
            </DialogActions>
        </Dialog>
        <Dialog
            open={open8}
            onClose={() => setOpen8(false)}
            aria-labelledby="dialog-title-5"
            aria-describedby="dialog-description-5"
            sx={{ p: 2 }}
            PaperProps={{
                style: {
                    backgroundColor: '#282520',
                },
            }}
        >
            <DialogTitle id="dialog-title-5">
                {calendar.length ? `Prenota per il ${calendar[selectedSpot.i].data} alle ${calendar[selectedSpot.i].ore[selectedSpot.j][selectedSpot.o].ora}` : ""}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="dialog-description-5">
                    Seleziona il motivo dell'appuntamento e inserisci i tuoi dati*
                </DialogContentText>
                <Stack style={{ display: 'flex' }} sx={{ mt: 2 }} spacing={2}>
                    <Select
                        value={selectedService.nome}
                        onChange={(event: any) => setSelectedService({ ...selectedService, nome: event.target.value, id: servizi.filter(s => s.nome == event.target.value)[0].id })}
                    >
                        {servizi.map(service => (<MenuItem value={service.nome}>{service.nome}</MenuItem>))}
                    </Select>
                    <TextField
                        required={selectedService.nome == 'Altro (Specificare)'}
                        label={selectedService.nome == 'Altro (Specificare)' ? 'Descrizione' : 'Note'}
                        value={selectedService.note}
                        onChange={(event: any) => setSelectedService({ ...selectedService, note: event.target.value })}
                        multiline
                        rows={3}
                    />
                    <TextField
                        required={true}
                        label={'Nome'}
                        value={userInfo.nome}
                        onChange={(event: any) => setUserInfo({ ...userInfo, nome: event.target.value })}
                    />
                    <TextField
                        required={true}
                        label={'Cognome'}
                        value={userInfo.cognome}
                        onChange={(event: any) => setUserInfo({ ...userInfo, cognome: event.target.value })}
                    />
                    <TextField
                        required={true}
                        label={'Email'}
                        value={userInfo.email}
                        onChange={(event: any) => setUserInfo({ ...userInfo, email: event.target.value })}
                    />
                    <Typography sx={{ color: 'rgba(255, 255, 255, 0.5)', px: 1 }}>
                        *verranno usati soltanto per fissare questo appuntamento. Per maggiori informazioni, vedi la privacy policy.
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen8(false)}>Annulla</Button>
                <Button
                    disabled={selectedService.nome == "Altro (Specificare)" && selectedService.note == ""}
                    onClick={handleBookingConfirm}
                >Prenota</Button>
            </DialogActions>
        </Dialog>
    </div >)
}

export default Landing;
