import axios from 'axios';
import { useState } from 'react';
import { Container, Typography, TextField, Paper, Stack, Grid, FormControlLabel, Checkbox, Button, Link, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress } from '@mui/material';

import Navbar from './Navbar';
import { LoadingButton } from '@mui/lab';

const Login = () => {
    const [useCookieStorage, setUseCookieStorage] = useState(true);

    const [open, setOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [statusMessage, setStatusMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [email, setEmail] = useState('');
    const [resetEmail, setResetEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleCheckboxChange = (event: any) => {
        setUseCookieStorage(event.target.checked);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        setStatusMessage("");

        try {
            const res = await axios.post('/api/login', { email, password, stayLogged: useCookieStorage });
            const { success, error, token, isOp } = res.data;
            if (success) {
                sessionStorage.setItem('jwt', token);
                if (useCookieStorage) {
                    const expirationDate = new Date('2038-01-01');
                    const cookieValue = token + `; expires=${expirationDate.toUTCString()}; path=/`;
                    document.cookie = `jwt=${cookieValue}`;
                }
                window.location.href = isOp ? '/op' : '/user';
            } else {
                setIsError(true);
                setIsLoading(false);
                setStatusMessage(error);
            }
        } catch (error: any) {
            setIsError(true);
            setIsLoading(false);
            setStatusMessage("Si è verificato un errore imprevisto sul nostro server.");
        }
    };

    const handleResetPassword = async (e: any) => {
        e.preventDefault();
        setOpen(false);
        setIsLoading(true);
        try {
            const res = await axios.post('/api/resetpassword', { email: resetEmail });
            const { success, error } = res.data;
            if (success) {
                setIsError(false);
                setIsLoading(false);
                setStatusMessage("Riceverai a breve una email per cambiare la tua password.");
            } else {
                setIsError(true);
                setIsLoading(false);
                setStatusMessage(error);
            }
        } catch (error: any) {
            setIsError(true);
            setIsLoading(false);
            setStatusMessage("Si è verificato un errore imprevisto sul nostro server.");
        }
        setResetEmail("");
    };

    return (
        <div>
            <Navbar logged={false} isOp={false} />
            <Container>
                <Typography variant="h3" align="center" color="#ECE9E6" gutterBottom sx={{ p: 2 }}>
                    Accedi a MyExperts
                </Typography>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item md={6}>
                        <Paper sx={{ px: 4, pt: 4, pb: 3, backgroundColor: '#282520' }}>
                            <form onSubmit={handleSubmit}>
                                <Stack spacing={3}>
                                    <TextField
                                        label="Email"
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                    <TextField
                                        label="Password"
                                        type="password"
                                        onChange={e => setPassword(e.target.value)}
                                    />
                                </Stack>
                                <Grid container sx={{ mt: 1, px: 2 }}>
                                    <Grid item xs>
                                        <FormControlLabel
                                            control={<Checkbox checked={useCookieStorage} onChange={handleCheckboxChange} color="primary" />}
                                            label="Rimani collegato"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Link href='#' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }} onClick={() => setOpen(true)} variant="body2">
                                            Password dimenticata?
                                        </Link>
                                    </Grid>
                                </Grid>
                                {statusMessage && (
                                    <Paper variant='outlined' sx={{
                                        p: 2, my: 1, flexGrow: 1,
                                        backgroundColor: isError ? "#e57373" : "#81c784",
                                        borderColor: isError ? '#d32f2f' : '#388e3c',
                                        color: 'black'
                                    }} role="alert">
                                        {statusMessage}
                                    </Paper>
                                )}
                                <LoadingButton
                                    type="submit"
                                    loading={isLoading}
                                    fullWidth
                                    variant="contained"
                                    sx={{ backgroundColor: '#53432D', color: '#ECE9E6', my: 2, fontSize: '110%' }}
                                >
                                    Entra
                                </LoadingButton>
                                <Typography align='center'>
                                    <span>Non hai un account? </span>
                                    <Link href="/register" variant="body2">
                                        Registrati
                                    </Link>
                                </Typography>
                            </form>
                        </Paper>
                    </Grid>
                </Grid>
            </Container >
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                sx={{ p: 2 }}
                PaperProps={{
                    style: {
                        backgroundColor: '#282520',
                    },
                }}
            >
                <DialogTitle id="dialog-title">
                    Password dimenticata
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-description">
                        Inserisci la tua email per reimpostare la tua password:
                    </DialogContentText>
                    <TextField
                        sx={{ mt: 2 }}
                        required
                        fullWidth
                        id="outlined-required"
                        label='Email'
                        value={resetEmail}
                        onChange={(event: any) => setResetEmail(event.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Annulla</Button>
                    <Button disabled={resetEmail == ""} onClick={handleResetPassword}>Conferma</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default Login;
