import { Container, Typography } from '@mui/material';
import Navbar from './Navbar';


const Page404 = () => {
    return (<div>
        <Navbar logged={false} isOp={false} />
        <Container>
            <Typography variant="h3" align='center' color="#ECE9E6" gutterBottom sx={{ p: 2 }}>
                Questa pagina non esiste...
            </Typography>
        </Container>
    </div>)
}

export default Page404;
