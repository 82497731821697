import { useState, useEffect } from 'react';
import axios from 'axios';
import utils from './utils';

import { Container, Typography, TextField, Paper, Stack, Grid, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, ButtonBase, IconButton, InputAdornment, SxProps, Select, MenuItem, FormControl } from '@mui/material';

import Navbar from './Navbar';
import { LoadingButton } from '@mui/lab';
import { ArrowBackIos, ArrowForwardIos, DeleteOutline, Search } from '@mui/icons-material';


type Service = {
    id: number,
    nome: string,
    testoEmail: string
}

const days: string[] = ['l', 'ma', 'me', 'g', 'v', 's', 'd'];
const daysName: { [d: string]: string } = {
    l: "Lunedì",
    ma: "Martedì",
    me: "Mercoledì",
    g: "Giovedì",
    v: "Venerdì",
    s: "Sabato",
    d: "Domenica",
};
type Orario = { l: string[]; ma: string[]; me: string[]; g: string[]; v: string[]; s: string[]; d: string[] }
const orari: string[] = [];
orari.push("-");
for (let o = 0; o < 24; o++) {
    orari.push(`${o.toString().padStart(2, '0')}:00`);
    orari.push(`${o.toString().padStart(2, '0')}:30`);
}

const dataRowSX: SxProps = {
    display: "table-row",
    ":hover": {
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        cursor: "pointer",
    },
};

const mouseDown = (e: any) => {
    e.stopPropagation();
}

const emptyFormData = {
    nome: '',
    cognome: '',
    telefono: '',
    codiceFiscale: '',
    partitaIva: '',
    nomeAzienda: '',
    indirizzo: '',
    citta: '',
    cap: '',
    provincia: '',
    email: '',
    kbUtilizzati: 0,
    scadenzaAbbonamento: 0,
};

const AdminDetail = () => {

    const [formData, setFormData] = useState(emptyFormData);

    const [statusMessage, setStatusMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isOp, setIsOp] = useState(false);

    const [statusMessageOrario, setStatusMessageOrario] = useState('');
    const [isLoadingOrario, setIsLoadingOrario] = useState(true);
    const [orario, setOrario] = useState(null as Orario | null);
    const [isNewOrario, setIsNewOrario] = useState(false);

    const [open, setOpen] = useState(false); //update service
    const [open2, setOpen2] = useState(false); //delete service
    const [open3, setOpen3] = useState(false); //create service
    const [services, setServices] = useState([] as Service[]);
    const [serviceSearch, setServiceSearch] = useState("");
    const [serviceTotal, setServiceTotal] = useState(0);
    const [servicePage, setServicePage] = useState(0);
    const [statusMessageServizi, setStatusMessageServizi] = useState('');
    const [isLoadingServizi, setIsLoadingServizi] = useState(true);
    const [selectedService, setSelectedService] = useState({} as Service);

    const urlParams = new URLSearchParams(window.location.search);
    const id = Number(urlParams.get('id') || 0);

    useEffect(() => {
        loadData(false);
    }, []);

    useEffect(() => {
        loadServices(0, "");
        loadOrario();
    }, [isOp]);

    const loadData = async (show: boolean) => {
        try {
            const res = await axios.get(`/api/admincliente?id=${id}`, { headers: utils.getAuthHeaders(), validateStatus: () => true });
            if (res.status == 200) {
                const { success, error, user } = res.data;
                if (success) {
                    setIsOp(user.op);
                    setFormData({
                        nome: user.nome,
                        cognome: user.cognome,
                        telefono: user.telefono,
                        codiceFiscale: user.cod_fiscale,
                        partitaIva: user.partita_iva,
                        nomeAzienda: user.nome_azienda,
                        indirizzo: user.indirizzo,
                        citta: user.citta,
                        cap: user.cap,
                        provincia: user.provincia,
                        email: user.email,
                        kbUtilizzati: user.kb_utilizzati,
                        scadenzaAbbonamento: user.scadenza_abbonamento,
                    })
                    setIsLoading(false);
                    if (show) {
                        setIsError(false);
                        setStatusMessage("Informazioni aggiornate con successo.");
                    }
                    return; // stay on the page
                } else if (error) {
                    console.log(error);
                }
            }
        } catch (error) {
            console.log(error)
        }
        setIsLoading(false);
        if (process.env.REACT_APP_ENV != 'test') {
            window.location.href = '/login';
        }
    }

    const loadServices = async (p: number, q: string) => {
        setStatusMessageServizi('');
        setIsLoadingServizi(true);
        try {
            const res = await axios.get(`/api/servizi?id=${id}&page=${p}${q ? '&q=' + q : ""}`, { headers: utils.getAuthHeaders() });
            const { success, error, services, total } = res.data;
            if (success) {
                setServices(services.map((s: any) => { return { ...s, testoEmail: s.testo_email } }));
                setServiceTotal(total);
            } else {
                setStatusMessageServizi(error);
            }
        } catch (error) {
            setStatusMessageServizi("Si è verificato un errore imprevisto sul nostro server.");
            console.log(error)
        }
        setIsLoadingServizi(false);
    }

    const loadOrario = async () => {
        setStatusMessageOrario('');
        setIsLoadingOrario(true);
        try {
            const res = await axios.get(`/api/adminorario?id=${id}`, { headers: utils.getAuthHeaders() });
            const { success, error, orario } = res.data;
            if (success) {
                setOrario(orario);
                let isNew = true;
                for (const d of days) {
                    if (orario[d].length) {
                        isNew = false;
                        break;
                    }
                }
                if (isNew) {
                    setIsNewOrario(true);
                } else {
                    setIsNewOrario(false);
                }
            } else {
                setStatusMessageOrario(error);
            }
        } catch (error) {
            setStatusMessageOrario("Si è verificato un errore imprevisto sul nostro server.");
            console.log(error)
        }
        setIsLoadingOrario(false);
    }

    const handleServiceSearch = (e: any) => {
        setServicePage(0);
        setServiceSearch(e.target.value);
        loadServices(0, e.target.value);
    }

    const handleServicePage = (forward: boolean) => {
        if (servicePage == 0 && !forward) {
            return;
        }
        if (5 * (servicePage + 1) >= serviceTotal && forward) {
            return;
        }
        const newPage = servicePage + (forward ? 1 : -1)
        setServicePage(newPage);
        loadServices(newPage, serviceSearch);
    }

    const handleSubmitInfo = async (e: any) => {
        e.preventDefault();
        try {
            setStatusMessage("");
            setIsLoading(true);
            const res = await axios.post(`/api/adminupdateuser?id=${id}`, formData, { headers: utils.getAuthHeaders() });
            const { success, error } = res.data;
            if (success) {
                loadData(true);
            } else {
                setIsError(true);
                setStatusMessage(error);
            }
        } catch (error) {
            setIsError(true);
            setStatusMessage("Si è verificato un errore imprevisto sul nostro server.");
            console.log(error)
        }
        setIsLoading(false);
    };
 
    const handleSubmitOrario = async (e: any) => {
        e.preventDefault();

        try {
            setStatusMessageOrario("");
            setIsLoadingOrario(true);
            const res = await axios.post('/api/adminupdateorario', { id, orario }, { headers: utils.getAuthHeaders() });
            const { success, error } = res.data;
            if (success) {
                return loadOrario();
            } else {
                setStatusMessageOrario(error);
                setIsLoadingOrario(false);
            }
        } catch (error) {
            setStatusMessageOrario("Si è verificato un errore imprevisto sul nostro server.");
            setIsLoadingOrario(false);
            console.log(error)
        }
    };

    const handleCreateConfirm = async (e: any) => {
        e.preventDefault();
        try {
            setOpen3(false);
            setStatusMessageServizi("");
            setIsLoadingServizi(true);
            const res = await axios.post(`/api/admincreateservizio?id=${id}`, { ...selectedService }, { headers: utils.getAuthHeaders() });
            const { success, error } = res.data;
            if (success) {
                setServicePage(0);
                setServiceSearch("");
                loadServices(0, "");
            } else {
                setStatusMessageServizi(error);
            }
        } catch (error) {
            setStatusMessageServizi("Si è verificato un errore imprevisto sul nostro server.");
            console.log(error)
        }
        setIsLoadingServizi(false);
        setSelectedService({} as Service);
    };

    const handleUpdateConfirm = async (e: any) => {
        e.preventDefault();
        try {
            setOpen(false);
            setStatusMessageServizi("");
            setIsLoadingServizi(true);
            const res = await axios.post(`/api/adminupdateservizio?id=${id}`, { ...selectedService }, { headers: utils.getAuthHeaders() });
            const { success, error } = res.data;
            if (success) {
                setServicePage(0);
                setServiceSearch("");
                loadServices(0, "");
            } else {
                setStatusMessageServizi(error);
            }
        } catch (error) {
            setStatusMessageServizi("Si è verificato un errore imprevisto sul nostro server.");
            console.log(error)
        }
        setIsLoadingServizi(false);
        setSelectedService({} as Service);
    };

    const handleDeleteConfirm = async (e: any) => {
        e.preventDefault();
        try {
            setOpen2(false);
            setStatusMessageServizi("");
            setIsLoadingServizi(true);
            const res = await axios.post(`/api/admindeleteservizio?id=${id}`, { id: selectedService.id }, { headers: utils.getAuthHeaders() });
            const { success, error } = res.data;
            if (success) {
                setServicePage(0);
                setServiceSearch("");
                loadServices(0, "");
            } else {
                setStatusMessageServizi(error);
            }
        } catch (error) {
            setStatusMessageServizi("Si è verificato un errore imprevisto sul nostro server.");
            console.log(error)
        }
        setIsLoadingServizi(false);
        setSelectedService({} as Service);
    };

    const openDeleteDialog = (e: any, service: any) => {
        e.stopPropagation();
        setSelectedService(service);
        setOpen2(true);
    }

    const handleClose2 = () => {
        setSelectedService({} as Service);
        setOpen2(false);
    };

    const openServizio = (service: Service) => {
        setSelectedService(service);
        setOpen(true);
    };

    const handleClose = () => {
        setSelectedService({} as Service);
        setOpen(false);
    };

    const handleClose3 = () => {
        setSelectedService({} as Service);
        setOpen3(false);
    };

    const handleChange = (e: any) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const updateOrarioDalle = (day: string, ora: string) => {
        const tmpOrario: any = { ...orario };
        if (ora == '-') {
            tmpOrario[day] = [];
        } else {
            if (tmpOrario[day].length) {
                tmpOrario[day][0] = ora;
            } else {
                tmpOrario[day] = [ora];
            }
        }
        setOrario(tmpOrario);
    }

    const updateOrarioAlle = (day: string, ora: string) => {
        const tmpOrario: any = { ...orario };
        if (ora == '-') {
            tmpOrario[day] = tmpOrario[day].slice(0, 1);
        } else {
            if (tmpOrario[day].length >= 2) {
                tmpOrario[day][1] = ora;
            } else {
                tmpOrario[day].push(ora);
            }
        }
        setOrario(tmpOrario);
    }

    const updateOrarioPausa = (day: string, p: number, ora: string) => {
        const tmpOrario: any = { ...orario };
        if (ora == '-') {
            tmpOrario[day] = tmpOrario[day].slice(0, 2 + p);
        } else {
            if (tmpOrario[day].length > 2 + p) {
                tmpOrario[day][2 + p] = ora;
            } else {
                tmpOrario[day].push(ora);
            }
        }
        setOrario(tmpOrario);
    }

    return (
        <div>
            <Container>
                <Typography variant="h3" align="center" color="#ECE9E6" gutterBottom sx={{ p: 2, mb: 1 }}>
                    {formData.nome} {formData.cognome}
                </Typography>
                <Paper sx={{ px: 4, pt: 4, pb: 1, backgroundColor: '#282520' }}>
                    {isLoading ? (<Stack sx={{ mt: 5, mb: 7 }} alignItems='center'><CircularProgress disableShrink /></Stack>) : (
                        <form onSubmit={handleSubmitInfo}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField label="Email" fullWidth
                                        name='email'
                                        value={formData.email}
                                        size='small'
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Scadenza Abbonamento"
                                        name='scadenzaAbbonamento'
                                        fullWidth
                                        size='small'
                                        value={new Date(formData.scadenzaAbbonamento).toLocaleDateString()}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Mb Utilizzati"
                                        name='mbUtilizzati'
                                        fullWidth
                                        size='small'
                                        value={Math.round(formData.kbUtilizzati / 1000)}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Nome"
                                        name='nome'
                                        fullWidth
                                        size='small'
                                        value={formData.nome}
                                        onChange={handleChange}
                                        required
                                        onInvalid={F => (F.target as HTMLInputElement).setCustomValidity('Questo campo è obbligatorio')}
                                        onInput={F => (F.target as HTMLInputElement).setCustomValidity('')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Cognome"
                                        name='cognome'
                                        fullWidth
                                        size='small'
                                        value={formData.cognome}
                                        onChange={handleChange}
                                        required
                                        onInvalid={F => (F.target as HTMLInputElement).setCustomValidity('Questo campo è obbligatorio')}
                                        onInput={F => (F.target as HTMLInputElement).setCustomValidity('')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField label="Telefono" fullWidth required type="tel"
                                        size='small'
                                        name='telefono'
                                        value={formData.telefono}
                                        onChange={handleChange}
                                        onInvalid={F => (F.target as HTMLInputElement).setCustomValidity('Questo campo è obbligatorio')}
                                        onInput={F => (F.target as HTMLInputElement).setCustomValidity('')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField label="Codice Fiscale" fullWidth required
                                        size='small'
                                        name='codiceFiscale'
                                        value={formData.codiceFiscale}
                                        onChange={handleChange}
                                        onInvalid={F => (F.target as HTMLInputElement).setCustomValidity('Questo campo è obbligatorio')}
                                        onInput={F => (F.target as HTMLInputElement).setCustomValidity('')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField label="Partita IVA"
                                        fullWidth
                                        name='partitaIva'
                                        value={formData.partitaIva}
                                        onChange={handleChange}
                                        size='small' />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField label="Nome Azienda"
                                        fullWidth
                                        name='nomeAzienda'
                                        value={formData.nomeAzienda}
                                        onChange={handleChange}
                                        size='small' />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label="Indirizzo" fullWidth required
                                        name='indirizzo'
                                        value={formData.indirizzo}
                                        onChange={handleChange}
                                        size='small'
                                        onInvalid={F => (F.target as HTMLInputElement).setCustomValidity('Questo campo è obbligatorio')}
                                        onInput={F => (F.target as HTMLInputElement).setCustomValidity('')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField label="Città" fullWidth required
                                        name='citta'
                                        size='small'
                                        value={formData.citta}
                                        onChange={handleChange}
                                        onInvalid={F => (F.target as HTMLInputElement).setCustomValidity('Questo campo è obbligatorio')}
                                        onInput={F => (F.target as HTMLInputElement).setCustomValidity('')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField label="CAP" fullWidth required
                                        name='cap'
                                        size='small'
                                        value={formData.cap}
                                        onChange={handleChange}
                                        onInvalid={F => (F.target as HTMLInputElement).setCustomValidity('Questo campo è obbligatorio')}
                                        onInput={F => (F.target as HTMLInputElement).setCustomValidity('')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField label="Provincia" fullWidth required
                                        name='provincia'
                                        size='small'
                                        value={formData.provincia}
                                        onChange={handleChange}
                                        onInvalid={F => (F.target as HTMLInputElement).setCustomValidity('Questo campo è obbligatorio')}
                                        onInput={F => (F.target as HTMLInputElement).setCustomValidity('')}
                                    />
                                </Grid>
                                {statusMessage && (
                                    <Paper variant='outlined' sx={{
                                        p: 2, ml: 2, mt: 2, width: '100%',
                                        backgroundColor: isError ? "#e57373" : "#81c784",
                                        borderColor: isError ? '#d32f2f' : '#388e3c',
                                        color: 'black'
                                    }} role="alert">
                                        {statusMessage}
                                    </Paper>
                                )}
                                <Grid container>
                                    <Grid item xs={12} sx={{ pl: 2 }}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ backgroundColor: '#53432D', color: '#ECE9E6', mb: 3, mt: 2, fontSize: '110%' }}
                                        >
                                            Aggiorna informazioni
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>)}
                </Paper> 
                {isOp && orario ? (<Paper sx={{ p: 3, mt: 3, backgroundColor: '#282520' }}>
                    <Typography variant="h4" color="#ECE9E6" gutterBottom sx={{ pl: 1, mb: 1 }}>
                        {isNewOrario ? "Imposta i tuoi Orari!" : "I tuoi Orari"}
                    </Typography> 
                    <form onSubmit={handleSubmitOrario}>
                        <TableContainer sx={{ width: '100%', overflowX: 'auto' }}>
                            <Table size='small' sx={{ mt: 3 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ color: "rgba(255, 255, 255, 0.4)" }}>GIORNO</TableCell>
                                        <TableCell sx={{ color: "rgba(255, 255, 255, 0.4)" }}>DALLE</TableCell>
                                        <TableCell sx={{ color: "rgba(255, 255, 255, 0.4)" }}>ALLE</TableCell>
                                        <TableCell sx={{ color: "rgba(255, 255, 255, 0.4)" }}>PAUSE</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {days.map(day => {
                                        const key = day as keyof Orario;
                                        const list = orario[key];
                                        if (list.length == 0) {
                                            return (
                                                <TableRow>
                                                    <TableCell>{daysName[day]}</TableCell>
                                                    <TableCell><FormControl sx={{ minWidth: 50 }} size="small">
                                                        <Select
                                                            value={'-'}
                                                            onChange={(event: any) => updateOrarioDalle(day, event.target.value)}
                                                        >
                                                            {orari.map(o => (<MenuItem value={o}>{o}</MenuItem>))}
                                                        </Select></FormControl></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            )
                                        } else if (list.length == 1) {
                                            return (
                                                <TableRow>
                                                    <TableCell>{daysName[day]}</TableCell>
                                                    <TableCell><FormControl sx={{ minWidth: 50 }} size="small">
                                                        <Select
                                                            value={list[0]}
                                                            onChange={(event: any) => updateOrarioDalle(day, event.target.value)}
                                                        >
                                                            {orari.map(o => (<MenuItem value={o}>{o}</MenuItem>))}
                                                        </Select></FormControl></TableCell>
                                                    <TableCell><FormControl sx={{ minWidth: 50 }} size="small">
                                                        <Select
                                                            value={'-'}
                                                            onChange={(event: any) => updateOrarioAlle(day, event.target.value)}
                                                        >
                                                            {orari.map(o => (<MenuItem value={o}>{o}</MenuItem>))}
                                                        </Select></FormControl></TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            )
                                        } else {
                                            const totPauses = list.length - 2;
                                            const pauses = [];
                                            for (let i = 0; i < totPauses; i++) {
                                                pauses.push(i);
                                            }
                                            pauses.push(-1); // to add a new one
                                            return (
                                                <TableRow>
                                                    <TableCell>{daysName[day]}</TableCell>
                                                    <TableCell><FormControl sx={{ minWidth: 50 }} size="small">
                                                        <Select
                                                            value={list[0]}
                                                            onChange={(event: any) => updateOrarioDalle(day, event.target.value)}
                                                        >
                                                            {orari.map(o => (<MenuItem value={o}>{o}</MenuItem>))}
                                                        </Select></FormControl></TableCell>
                                                    <TableCell><FormControl sx={{ minWidth: 50 }} size="small">
                                                        <Select
                                                            value={list[1]}
                                                            onChange={(event: any) => updateOrarioAlle(day, event.target.value)}
                                                        >
                                                            {orari.map(o => (<MenuItem value={o}>{o}</MenuItem>))}
                                                        </Select></FormControl></TableCell>
                                                    <TableCell>
                                                        <Stack spacing={1} direction='row'>
                                                            {pauses.map(p => (
                                                                <FormControl sx={{ minWidth: 50 }} size="small">
                                                                    <Select
                                                                        value={p == -1 ? '-' : list[2 + p]}
                                                                        onChange={p == -1 ?
                                                                            (event: any) => updateOrarioPausa(day, pauses.length - 1, event.target.value) :
                                                                            (event: any) => updateOrarioPausa(day, p, event.target.value)
                                                                        }
                                                                    >
                                                                        {orari.map(o => (<MenuItem value={o}>{o}</MenuItem>))}
                                                                    </Select></FormControl>
                                                            ))}
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {statusMessageOrario && (
                            <Paper variant='outlined' sx={{
                                p: 2, mt: 2, flexGrow: 1,
                                backgroundColor: "#e57373",
                                borderColor: '#d32f2f',
                                color: 'black'
                            }} role="alert">
                                {statusMessageOrario}
                            </Paper>
                        )}
                        <Grid container>
                            <Grid item xs={12}>
                                <LoadingButton
                                    type="submit"
                                    loading={isLoadingOrario}
                                    fullWidth
                                    variant="contained"
                                    sx={{ backgroundColor: '#53432D', color: '#ECE9E6', mt: 2, mb: 1, fontSize: '110%' }}
                                >
                                    Aggiorna Orario
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>) : <Typography />}
                {isOp ? (<Paper sx={{ px: 3, pt: 3, pb: 2, mt: 3, backgroundColor: '#282520' }}>
                    <Typography variant="h4" color="#ECE9E6" gutterBottom sx={{ pl: 1, mb: 2 }}>
                        I tuoi Servizi
                    </Typography>
                    {isLoadingServizi ? (<Stack sx={{ my: 3 }} alignItems='center'><CircularProgress disableShrink /></Stack>) :
                        (<div>
                            <div style={{ display: 'flex' }}>
                                <TextField
                                    id="outlined-size-small"
                                    size="small"
                                    placeholder='Cerca...'
                                    onChange={handleServiceSearch}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Search />
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{ flexGrow: 1 }}
                                />
                            </div>
                            <TableContainer sx={{ width: '100%', overflowX: 'auto' }}>
                                <Table size='small' sx={{ mt: 3 }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ color: "rgba(255, 255, 255, 0.4)" }}>NOME</TableCell>
                                            <TableCell align='center' sx={{ color: "rgba(255, 255, 255, 0.4)" }}>ELIMINA</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {services.map(service => {
                                            return (<ButtonBase onClick={() => openServizio(service)} key={service.id} component={TableRow} sx={dataRowSX}>
                                                <TableCell>{service.nome}</TableCell>
                                                <TableCell align='center'>
                                                    <IconButton sx={{ p: 0 }} onMouseDown={mouseDown} onClick={(e) => openDeleteDialog(e, service)}>
                                                        <DeleteOutline color='error' />
                                                    </IconButton>
                                                </TableCell>
                                            </ButtonBase>)
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Stack direction='row' alignItems='center' sx={{ pt: 1 }}>
                                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
                                <Typography fontSize='1.1rem' sx={{ pr: 2, color: "rgba(255, 255, 255, 0.8)" }}>
                                    {`${servicePage * 5 + 1} - ${servicePage * 5 + services.length} di ${serviceTotal}`}
                                </Typography>
                                <IconButton onClick={() => handleServicePage(false)} disabled={servicePage == 0}><ArrowBackIos /></IconButton>
                                <IconButton onClick={() => handleServicePage(true)} disabled={5 * (servicePage + 1) >= serviceTotal}><ArrowForwardIos /></IconButton>
                            </Stack>
                        </div>)}
                    {statusMessageServizi && (
                        <Paper variant='outlined' sx={{
                            p: 2, my: 1, flexGrow: 1,
                            backgroundColor: "#e57373",
                            borderColor: '#d32f2f',
                            color: 'black'
                        }} role="alert">
                            {statusMessageServizi}
                        </Paper>
                    )}
                    <Button
                        component="label"
                        fullWidth
                        onClick={() => setOpen3(true)}
                        variant="outlined"
                        sx={{ my: 1 }}
                    >
                        Crea nuovo
                    </Button>
                </Paper>) : <Typography />}
            </Container >
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                sx={{ p: 2 }}
                PaperProps={{
                    style: {
                        backgroundColor: '#282520',
                    },
                }}
            >
                <DialogTitle id="dialog-title">
                    Modifica il Servizio
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-description">
                        Ricorda che i clienti che hanno già prenotazioni per questo servizio non avranno notizia di questo aggiornamento.
                    </DialogContentText>
                    <Stack style={{ display: 'flex' }} sx={{ mt: 2 }} spacing={2}>
                        <TextField
                            required
                            label='Nome'
                            value={selectedService.nome}
                            onChange={(event: any) => setSelectedService({ ...selectedService, nome: event.target.value })}
                        />
                        <TextField
                            label='Testo Email'
                            value={selectedService.testoEmail}
                            onChange={(event: any) => setSelectedService({ ...selectedService, testoEmail: event.target.value })}
                            multiline
                            rows={7}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Annulla</Button>
                    <Button
                        disabled={selectedService.nome == ""}
                        onClick={handleUpdateConfirm}
                    >Conferma</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open2}
                onClose={handleClose2}
                aria-labelledby="dialog-title-1"
                aria-describedby="dialog-description-1"
                sx={{ p: 2 }}
                PaperProps={{
                    style: {
                        backgroundColor: '#282520',
                    },
                }}
            >
                <DialogTitle id="dialog-title-1">
                    {`Eliminare il Servizio ${selectedService.nome}?`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-description-1">
                        Questa operazione non può essere annullata. Sei sicuro di voler eliminare il servizio?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose2}>Annulla</Button>
                    <Button
                        color='error'
                        onClick={handleDeleteConfirm}
                    >Elimina</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open3}
                onClose={handleClose3}
                aria-labelledby="dialog-title-2"
                aria-describedby="dialog-description-2"
                sx={{ p: 2 }}
                PaperProps={{
                    style: {
                        backgroundColor: '#282520',
                    },
                }}
            >
                <DialogTitle id="dialog-title-2">
                    Crea un nuovo Servizio
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-description-2">
                        Questo servizio sarà prenotabile da tutti i tuoi clienti. Alla prenotazione invieremo loro una email di conferma.
                        Se vuoi inserire dettagli aggiuntivi (ad esempio: che documenti portare all'appuntamento o altre informazioni importanti),
                        puoi inserirli nel campo "Testo Email".
                    </DialogContentText>
                    <Stack style={{ display: 'flex' }} sx={{ mt: 2 }} spacing={2}>
                        <TextField
                            required
                            label='Nome'
                            value={selectedService.nome}
                            onChange={(event: any) => setSelectedService({ ...selectedService, nome: event.target.value })}
                        />
                        <TextField
                            label='Testo Email'
                            value={selectedService.testoEmail}
                            onChange={(event: any) => setSelectedService({ ...selectedService, testoEmail: event.target.value })}
                            multiline
                            rows={7}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose3}>Annulla</Button>
                    <Button
                        disabled={selectedService.nome == ""}
                        onClick={handleCreateConfirm}
                    >Crea</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default AdminDetail;
