import axios from 'axios';
import utils from './utils';
import { useEffect, useState } from 'react';
import { Container, Typography, Paper, Stack, CircularProgress } from '@mui/material';

import Navbar from './Navbar';


const Subscription = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [email, setEmail] = useState("");
    const [scadenza, setScadenza] = useState(0);

    useEffect(() => {
        loadData();
    }, []);

    const now = Date.now();

    const loadData = async () => {
        try {
            const res = await axios.get('/api/me', { headers: utils.getAuthHeaders(), validateStatus: () => true });
            if (res.status == 200) {
                const { success, error, me } = res.data;
                if (success) {
                    setIsLoading(false);
                    setScadenza(me.scadenza_abbonamento);
                    setEmail(me.email);
                    if (me.op) {
                        return; // stay on the page
                    }
                } else if (error) {
                    console.log(error);
                }
            }
        } catch (error) {
            console.log(error)
        }
        setIsLoading(false);
        if (process.env.REACT_APP_ENV != 'test') {
            window.location.href = '/login';
        }
    }

    return (
        <div>
            <Navbar logged={true} isOp={true} />
            <Container>
                <Typography variant="h3" color="#ECE9E6" gutterBottom sx={{ p: 2 }}>
                   Il tuo Abbonamento a MyExperts.
                </Typography>
                <Paper sx={{ px: 3, pt: 3, pb: 2, backgroundColor: '#282520' }}>
                    {isLoading ? (<Stack sx={{ mt: 5 }} alignItems='center'><CircularProgress disableShrink /></Stack>) : (
                        <div>
                            <Typography  sx={{ pl: 1, mb: 1 }}>
                                {`Il tuo abbonamento a MyExperts ${scadenza < now ?
                                    ` è scaduto il ${new Date(scadenza).toLocaleDateString()}. Rinnovalo al più presto ` :
                                    (scadenza < now + 7 * 24 * 60 * 60 * 1000 ?
                                        ` è in scadenza il ${new Date(scadenza).toLocaleDateString()}. Rinnovalo ` :
                                        ` è valido fino al ${new Date(scadenza).toLocaleDateString()}. Puoi rinnovarlo `)}` +
                                "tramite bonifico alle seguenti coordinate:"}
                            </Typography>
                            <Typography sx={{ pl: 1 }}><span style={{ fontWeight: 'bold' }}>Intestatario: </span>Michele Roselli</Typography>
                            <Typography sx={{ pl: 1 }}><span style={{ fontWeight: 'bold' }}>IBAN: </span>IT72M0200811756000106806653</Typography>
                            <Typography sx={{ pl: 1, mb: 1 }}><span style={{ fontWeight: 'bold' }}>Causale: </span>{`Canone annuale MyExperts - ${email}`}</Typography>
                            <Typography sx={{ pl: 1, mb: 1 }}>{`Riceverai la fattura al tuo indirizzo: ${email}`}</Typography> 
                        </div>)
                    }
                </Paper>
            </Container >
        </div >
    );
};

export default Subscription;
