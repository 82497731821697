import axios from "axios";
const { v4: uuidv4 } = require('uuid');

const getCookie = (name: string) => {
  const cookieName = `${name}=`;
  const cookieArray = document.cookie.split(';');
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return '';
};

const getOrCreateLeadId = () => {
  let id = sessionStorage.getItem('leadid');
  if (!id) {
    id = uuidv4();
    sessionStorage.setItem('leadid', id!);
  }
  return id;
}

const sendAnalyticsHearthbeat = (page: string) => {
  const leadId = getOrCreateLeadId();
  axios.post('/api/analytics', { id: leadId, page, type: 'hearthbeat', timestamp: Date.now() }).catch(() => {});
}

const sendAnalyticsEvent = (name: string, error: string | null) => {
  const leadId = getOrCreateLeadId();
  axios.post('/api/analytics', { id: leadId, type: 'event', timestamp: Date.now(), event: name, error }).catch(() => {});
}

const getAuthHeaders = () => {
  const jwt = sessionStorage.getItem('jwt') || getCookie('jwt') || '';
  return { 'Authorization': `Bearer ${jwt}` };
}

const getSocketParams = (id: number) => {
  const jwt = sessionStorage.getItem('jwt') || getCookie('jwt') || '';
  return { path: '/api/socket.io', transports: ["websocket"], query: { token: jwt, id } };
}

const formatData = (ts: number) => {
    const date = new Date(ts);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear().toString().substring(2)}`
}

export default {
  getAuthHeaders,
  getSocketParams,
  sendAnalyticsHearthbeat,
  sendAnalyticsEvent,
  formatData,
}
