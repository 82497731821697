import { Container, Typography } from '@mui/material';
import Navbar from './Navbar';
import Page404 from './Page404';


const Terms = (props: { page: string }) => {
    if (props.page == 'terms') {
        return (<div>
            <Navbar logged={false} isOp={false} />
            <Container>
                <Typography variant="h3" align='center' color="#ECE9E6" gutterBottom sx={{ pt: 2 }}>
                    Termini di Servizio
                </Typography>
                <Typography variant="h5" color="#ECE9E6" gutterBottom>
                    Descrizione del servizio offerto
                </Typography>
                <Typography color="#ECE9E6" gutterBottom>
                    Il servizio si identifica in un’applicazione fornita nella soluzione “software as a service”,
                    ovvero utilizzabile tramite un qualsiasi browser connettendosi al sito Web dedicato.
                    L’applicazione prevede l’utilizzo da parte di due categorie di utenti: i professionisti e i clienti. I
                    primi, previa registrazione, hanno la possibilità di inserire i propri orari di lavoro e i servizi da
                    loro offerti, che saranno poi visibili ai clienti. Questi ultimi potranno quindi prenotare i servizi
                    sull’agenda del professionista. Entrambe le tipologie di clienti avranno accesso alla chat
                    privata e alla possibilità di interscambio di file, che verranno archiviati all’interno
                    dell’applicazione.
                </Typography>
                <Typography variant="h5" color="#ECE9E6" gutterBottom>
                    Garanzie e diritti di utilizzo
                </Typography>
                <Typography color="#ECE9E6" gutterBottom>
                    Agli utenti è garantita l’accessibilità al servizio senza limitazioni nell’arco delle 24 ore, salvi
                    casi di malfunzionamento dei server di hosting o durante aggiornamenti di sistema.
                    Per le garanzie sui dati personali, si rimanda alla successiva informativa sulla privacy
                </Typography>
                <Typography variant="h5" color="#ECE9E6" gutterBottom>
                    Diritto di recesso
                </Typography>
                <Typography color="#ECE9E6" gutterBottom>
                    L’iscrizione al servizio prevede un mese di prova gratuita: al termine dello stesso, si
                    rinnoverà per un periodo di 365 giorni.

                    Non sarà possibile esercitare il servizio di recesso di cui agli artt. 52 e segg. D.Lgs nr.
                    206/05 e non sarà oggetto di rimborso l’eventuale periodo già anticipatamente corrisposto.
                    In ogni momento, l’utente potrà cancellare la propria iscrizione pena la perdita della quota
                    già corrisposta anticipatamente.
                    La cancellazione dal servizio viene intesa come volontà dell’utente di non usufruire,
                    nemmeno successivamente, del periodo eventualmente non utilizzato.
                </Typography>
                <Typography variant="h5" color="#ECE9E6" gutterBottom>
                    Modalità di accesso al prodotto
                </Typography>
                <Typography color="#ECE9E6" gutterBottom>
                    Il prodotto è accessibile tramite il sito Web da browser PC o smartphone
                </Typography>
                <Typography variant="h5" color="#ECE9E6" gutterBottom>
                    Condizioni di utilizzo
                </Typography>
                <Typography color="#ECE9E6" gutterBottom>
                    La comunicazione di informazioni false e/o fraudolente non è consentita. Il gestore del
                    servizio si impegna, nel limite dei propri strumenti giuridici, a verificarne la veridicità ed alla
                    loro rimozione nei casi sopra previsti.
                    Fatta salva la responsabilità penale in relazione alle normative vigenti, per cui ci si riserva,
                    nel caso, la comunicazione alle Autorità competenti.
                </Typography>
                <Typography variant="h5" color="#ECE9E6" gutterBottom>
                    Informazioni su rischi e responsabilità
                </Typography>
                <Typography color="#ECE9E6" gutterBottom>
                    L’utilizzo del servizio prevede l’accettazione dei rischi connessi a malfunzionamento del
                    sistema – che potrebbe temporaneamente sospenderne l’offerta – attacchi informatici e/o
                    utilizzo fraudolento del sito.
                    Questi ultimi possono in linea teorica comportare:
                    1 la perdita, il cambiamento (o manomissione) dei propri dati inseriti precedentemente o
                    l’accesso indesiderato a tali dati da parte di terzi;
                    2 l’ottenimento di dati riservati da parte di un utente attraverso identità fraudolente: si precisa
                    che tutti i professionisti potranno essere registrati solo previo invio della copia di un
                    documento di identità.
                </Typography>
                <Typography variant="h5" color="#ECE9E6" gutterBottom>
                    Informazioni sui pagamenti
                </Typography>
                <Typography color="#ECE9E6" gutterBottom>
                    La corresponsione di quanto dovuto per il servizio avverrà esclusivamente a mezzo bonifico
                    bancario al termine del periodo di prova.
                    Del rinnovo annuale verrà fornita comunicazione anticipatamente a mezzo email.
                </Typography>
            </Container>
        </div>)
    } else if (props.page == 'privacy') {
        return (<div>
            <Navbar logged={false} isOp={false} />
            <Container>
                <Typography variant="h3" align='center' color="#ECE9E6" gutterBottom sx={{ pt: 2 }}>
                    Privacy Policy
                </Typography>
                <Typography variant="h5" color="#ECE9E6" gutterBottom>
                    Finalità del trattamento dei dati
                </Typography>
                <Typography color="#ECE9E6" gutterBottom>
                    I dati raccolti verranno utilizzati esclusivamente per le finalità del servizio offerto ovvero per
                    lo scambio tra professionista ed il richiedente.
                    I dati raccolti a mezzo documento di identità da parte dei professionisti registrati verranno
                    utilizzati unicamente al fine di verificare l’identità degli stessi.
                </Typography>
                <Typography variant="h5" color="#ECE9E6" gutterBottom>
                    Modalità del trattamento dei dati
                </Typography>
                <Typography color="#ECE9E6" gutterBottom>
                    Tutti i dati, ad eccezione dei file inviati (quest’ultimi caricati su un servizio di hosting dedicato
                    presso Aruba), verranno archiviati su un database privato situato in Italia presso i data
                    center di Aruba.
                    Previa richiesta al servizio di assistenza, in ogni momento le informazioni fornite possono
                    essere oggetto di cancellazione, revisione, modifica o aggiornamento in tutto o in parte.
                    Tutti i file oggetto di scambio tra cliente e professionista possono essere eliminati soltanto
                    dal professionista stesso.
                    I messaggi oggetto di scambio nella chat personale possono essere eliminati previa richiesta
                    all’assistenza.
                </Typography>
                <Typography variant="h5" color="#ECE9E6" gutterBottom>
                    Conferimento dei dati
                </Typography>
                <Typography color="#ECE9E6" gutterBottom>
                    La comunicazione dei propri dati anagrafici, oltre che di un valido indirizzo email, è
                    condizione essenziale.
                    In assenza di questi, non sarà possibile procedere all’attivazione del servizio.
                </Typography>
                <Typography variant="h5" color="#ECE9E6" gutterBottom>
                    Comunicazione e diffusione dei dati
                </Typography>
                <Typography color="#ECE9E6" gutterBottom>
                    Nessun dato verrà comunicato a terzi se non nell’interscambio tra professionista e cliente.
                    I dati inseriti dai professionisti rimangono visibili a tutti gli utilizzatori.
                    I dati inseriti dai clienti rimangono visibili solo ai professionisti con cui si sono interfacciati.
                </Typography>
                <Typography variant="h5" color="#ECE9E6" gutterBottom>
                    Cookie
                </Typography>
                <Typography color="#ECE9E6" gutterBottom>
                    L'applicazione utilizza cookie esclusivamente per salvare i codici di accesso alla stessa qualora
                    si desideri restare connessi tra una sessione e l'altra. Nessun'altra informazione o dato personale viene
                    salvato nei cookie, che possono essere eliminati in ogni momento uscendo dall'applicazione.
                </Typography>
                <Typography variant="h5" color="#ECE9E6" gutterBottom>
                    Titolare del trattamento dei dati
                </Typography>
                <Typography color="#ECE9E6" gutterBottom>
                    Dott. Michele Roselli, nato a Negrar il 02.01.1994, P. IVA 04903210237
                </Typography>
            </Container>
        </div>)
    } else {
        return (<Page404 />)
    }
}

export default Terms;
