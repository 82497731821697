import { useState } from 'react';
import axios from 'axios';

import { Container, Typography, TextField, Paper, Stack, Grid, Button, Link, } from '@mui/material';

import Navbar from './Navbar';
import Page404 from './Page404';
import { LoadingButton } from '@mui/lab';


const emptyFormData = {
    password: '',
    confirmPassword: '',
};

const PasswordReset = () => {

    // info dialog
    const [formData, setFormData] = useState(emptyFormData);

    const [isLoading, setIsLoading] = useState(false);

    const [statusMessage, setStatusMessage] = useState('');
    const [isError, setIsError] = useState(true);

    const urlParams = new URLSearchParams(window.location.search);
    const id = Number(urlParams.get('id'));
    const token = urlParams.get('token');
    if (!id || !token) {
        return (<div>
            <Navbar logged={false} isOp={false} />
            <Page404 />
        </div>);
    }

    const handleChange = (e: any) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const strongPassword = (str: string) => {
        return !!str && /(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})/.test(str);
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!isError) { // redirect to login page
            window.location.href = '/login';
            return;
        }
        setStatusMessage('');

        if (!strongPassword(formData.password)) {
            setIsError(true);
            setStatusMessage('La password deve essere lunga almeno 8 caretteri e contenere almeno una lettera e un numero.');
            return;
        }

        if (formData.password !== formData.confirmPassword) {
            setIsError(true);
            setStatusMessage('Le password non coincidono.');
            return;
        }

        try {
            setIsLoading(true);
            const res = await axios.post('/api/resetpasswordconfirm', { id, token, ...formData });
            const { success, error } = res.data;
            setIsLoading(false);
            if (success) {
                setIsError(false);
                setStatusMessage(`La password è stata impostata con successo.`);
            } else {
                setIsError(true);
                setStatusMessage(error);
                return;
            }
        } catch (error) {
            setIsError(true);
            setIsLoading(false);
            console.log(error)
            setStatusMessage("Si è verificato un errore imprevisto sul nostro server.");
            return;
        }
        setFormData(emptyFormData);
    };

    return (
        <div>
            <Navbar logged={false} isOp={false} />
            <Container>
                <Typography variant="h3" align="center" color="#ECE9E6" gutterBottom sx={{ p: 2 }}>
                    Imposta una nuova Password
                </Typography>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item md={6}>
                        <Paper sx={{ px: 4, pt: 4, pb: 3, backgroundColor: '#282520' }}>
                            <form onSubmit={handleSubmit}>
                                {isError ? (<Stack spacing={3}>
                                    <TextField
                                        label="Password"
                                        name='password'
                                        fullWidth
                                        required
                                        type="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        onInvalid={F => (F.target as HTMLInputElement).setCustomValidity('Questo campo è obbligatorio')}
                                        onInput={F => (F.target as HTMLInputElement).setCustomValidity('')}
                                    />
                                    <TextField
                                        label="Conferma Password"
                                        name='confirmPassword'
                                        fullWidth
                                        required
                                        type="password"
                                        value={formData.confirmPassword}
                                        onChange={handleChange}
                                        onInvalid={F => (F.target as HTMLInputElement).setCustomValidity('Questo campo è obbligatorio')}
                                        onInput={F => (F.target as HTMLInputElement).setCustomValidity('')}
                                    />
                                </Stack>) : (<Typography />)}

                                {statusMessage && (
                                    <Paper variant='outlined' sx={{
                                        p: 2, mt: 2, flexGrow: 1,
                                        backgroundColor: isError ? "#e57373" : "#81c784 ",
                                        borderColor: isError ? '#d32f2f' : '#388e3c',
                                        color: 'black'
                                    }} role="alert">
                                        {statusMessage}
                                    </Paper>
                                )}
                                <Grid container>
                                    <Grid item xs={12}>
                                        <LoadingButton
                                            type="submit"
                                            loading={isLoading}
                                            fullWidth
                                            variant="contained"
                                            sx={{ backgroundColor: '#53432D', color: '#ECE9E6', my: 3, fontSize: '110%' }}
                                        >
                                            {isError ? 'Cambia Password' : 'Accedi'}
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </form>
                            <Typography align='center'>
                                <span>Ti ricordi la vecchia password? </span>
                                <Link href="/login" variant="body2">
                                    Accedi
                                </Link>
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Container >
        </div >
    );
};

export default PasswordReset;
